export enum BackgroundColor {
	primary = 'bg-skin-button-base',
	primaryBrand1 = 'bg-primary-brand-1',
	primaryBrand2 = 'bg-primary-brand-2',
	secondary = 'bg-secondary',
	secondaryBrand1 = 'bg-secondary-brand-1',
	secondaryBrand2 = 'bg-secondary-brand-2',
	secondaryBrand3 = 'bg-secondary-brand-3',
	neutralLight = 'bg-neutral-100',
	neutral = 'bg-neutral-500',
	neutralDark = 'bg-neutral-800',
	neutralDarkest = 'bg-neutral-900',
	success = 'bg-success',
	danger = 'bg-danger',
	warning = 'bg-warning-500',
	info = 'bg-info-500',
	light = 'bg-light-500',
	dark = 'bg-dark-500',
	transparent = 'bg-transparent',
	white = 'bg-white',
	black = 'bg-black',
	blue = 'bg-blue-600',
}

export enum TextColor {
	primary = 'text-skin-base',
	primaryBrand1 = 'text-primary-brand-1',
	primaryBrand2 = 'text-primary-brand-2',
	secondary = 'text-secondary',
	secondaryBrand1 = 'text-secondary-brand-1',
	secondaryBrand2 = 'text-secondary-brand-2',
	secondaryBrand3 = 'text-secondary-brand-3',
	neutralLight = 'text-neutral-100',
	neutral = 'text-neutral-500',
	neutralDark = 'text-neutral-800',
	neutralDarkest = 'text-neutral-900',
	success = 'text-success',
	danger = 'text-danger',
	warning = 'text-warning-500',
	info = 'text-info-500',
	light = 'text-light-500',
	dark = 'text-dark-500',
	transparent = 'text-transparent',
	white = 'text-white',
	black = 'text-black',
	blue = 'text-blue-600',
}

export enum RingColor {
	primary = 'ring-primary',
	primaryBrand1 = 'ring-primary-brand-1',
	primaryBrand2 = 'ring-primary-brand-2',
	secondary = 'ring-secondary',
	secondaryBrand1 = 'ring-secondary-brand-1',
	secondaryBrand2 = 'ring-secondary-brand-2',
	secondaryBrand3 = 'ring-secondary-brand-3',
	neutralLight = 'ring-neutral-100',
	neutral = 'ring-neutral-500',
	neutralDark = 'ring-neutral-800',
	neutralDarkest = 'ring-neutral-900',
	success = 'ring-success',
	danger = 'ring-danger',
	warning = 'ring-warning-500',
	info = 'ring-info-500',
	light = 'ring-light-500',
	dark = 'ring-dark-500',
	transparent = 'ring-transparent',
	white = 'ring-white',
	black = 'ring-black',
	blue = 'ring-blue-600',
	avatar = 'ring-skin-avatar-base',
}

export enum TextSize {
	xs = 'text-xs',
	sm = 'text-sm',
	tiny = 'text-tiny',
	base = 'text-base',
	lg = 'text-lg',
	xl = 'text-xl',
	'2xl' = 'text-2xl',
	'3xl' = 'text-3xl',
	'4xl' = 'text-4xl',
	'5xl' = 'text-5xl',
	'6xl' = 'text-6xl',
}
