import { TrackEvent } from '@theme/analytics/analytics';
import { FeatureFlag } from '@theme/theme/features/feature-flags';

export interface NavigationLink {
	url: string;
	displayText: string;
	key: string;
	icon: string;
	featureFlag: boolean;
	featureFlagKey: string;
	showNotification: boolean;
	clickEventKey;
}

export const navigationLinks = [
	{
		url: '/dashboard/',
		displayText: 'Opportunities',
		key: 'feed',
		icon: 'ChartSquareBarIcon',
		featureFlag: false,
		featureFlagKey: null,
		showNotification: false,
		clickEventKey: TrackEvent.MenuOpportunitiesLinkClicked,
	},
	{
		url: '/dashboard/appointments/schedule/pending',
		displayText: 'Appointments',
		key: 'appointments',
		icon: 'CalendarIcon',
		featureFlag: false,
		featureFlagKey: null,
		showNotification: false,
		clickEventKey: TrackEvent.MenuAppointmentsLinkClicked,
	},
	{
		url: '/dashboard/requests',
		displayText: 'Requests',
		key: 'requests',
		icon: 'CollectionIcon',
		featureFlag: true,
		featureFlagKey: FeatureFlag.requests,
		showNotification: false,
		clickEventKey: TrackEvent.MenuRequestsLinkClicked,
	},
	{
		url: '/dashboard/challenges/list',
		displayText: 'Challenges',
		key: 'challenges',
		icon: 'PuzzleIcon',
		featureFlag: true,
		featureFlagKey: FeatureFlag.challenges,
		showNotification: false,
		clickEventKey: TrackEvent.MenuChallengesLinkClicked,
	},
	{
		url: '/dashboard/challenges/submissions',
		displayText: 'Challenge Submissions',
		key: 'challenge-submissions',
		icon: 'LightBulbIcon',
		featureFlag: true,
		featureFlagKey: FeatureFlag.challenges,
		showNotification: false,
		clickEventKey: TrackEvent.MenuChallengeSubmissionsLinkClicked,
	},
	{
		url: '/dashboard/chat',
		displayText: 'Chat',
		key: 'chat',
		icon: 'ChatIcon',
		featureFlag: false,
		featureFlagKey: null,
		showNotification: false,
		clickEventKey: TrackEvent.MenuChatLinkClicked,
	},
	{
		url: '/dashboard/team',
		displayText: 'Team',
		key: 'team',
		icon: 'UserGroupIcon',
		featureFlag: false,
		featureFlagKey: null,
		showNotification: false,
		clickEventKey: TrackEvent.MenuTeamLinkClicked,
	},
	{
		url: '/dashboard/saved',
		displayText: 'Saved',
		key: 'saved',
		icon: 'HeartIcon',
		featureFlag: false,
		featureFlagKey: null,
		showNotification: false,
		clickEventKey: TrackEvent.MenuSavedLinkClicked,
	},
].filter((code) => code.url && code.key && code.displayText && code.icon);

export default navigationLinks;

export interface ShowNotificationDefinitions {
	chat: boolean;
}

export const renderFeatureFlaggedNavigationLinks = (
	links: NavigationLink[],
	action: (key) => boolean,
	showNotificationDefinitions: ShowNotificationDefinitions,
): NavigationLink[] => {
	return links
		.map((i) => {
			if (showNotificationDefinitions[i.key]) {
				i.showNotification = true;
			}

			if (i.featureFlag === true) {
				if (action(i.featureFlagKey) === true) return i;
			} else {
				return i;
			}
		})
		.filter((i) => {
			return i !== undefined;
		});
};
