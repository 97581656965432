import {
	defaultTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import { useCompanyVerticals } from '@theme/hooks/useCompanyVerticals';
import { useFeatureToggle } from '@theme/hooks/useFeatureToggle';
import { FeatureFlag } from '@theme/theme/features/feature-flags';
import Button, { ButtonSize } from '@vintro/components/vintro/button';
import cc from 'classcat';
import Link from 'next/link';
import * as React from 'react';
import { useAnalytics } from 'use-analytics';

interface NavigationActionsProps {
	display: 'desktop' | 'mobile';
	setMobileMenuIsOpen?;
	mobileMenuIsOpen?;
}

export const NavigationActions: React.FC<NavigationActionsProps> = (props) => {
	const { track } = useAnalytics();
	const { display, setMobileMenuIsOpen, mobileMenuIsOpen } = props;
	const [isEnabled] = useFeatureToggle();
	const { isRequestCreator, isChallengeCreator } = useCompanyVerticals();

	const enableAddRequest = isEnabled(FeatureFlag.requests) && isRequestCreator;
	const enableAddChallenge =
		isEnabled(FeatureFlag.challenges) && isChallengeCreator;

	return (
		<>
			{display === 'desktop' && (enableAddRequest || enableAddChallenge) && (
				<span className="ml-6 mr-6 h-8 w-px bg-neutral-600"></span>
			)}
			<div
				className={cc([
					{
						'relative hidden sm:block': display === 'desktop',
					},
				])}
			>
				<div
					className={cc([
						{
							'flex flex-row space-x-4': display === 'desktop',
							'w-full p-2': display === 'mobile',
						},
					])}
				>
					{enableAddRequest && (
						<Link href={'/dashboard/requests/create'}>
							<Button
								size={ButtonSize.sm}
								buttonProps={{
									className: cc([
										'add-request-button',
										{
											'w-full justify-center mb-2': display === 'mobile',
										},
									]),
								}}
								onClick={() => {
									track(
										TrackEvent.NavigationAddRequestButtonClicked,
										defaultTrackingMetadata,
									);
									if (display === 'mobile') {
										setMobileMenuIsOpen(!mobileMenuIsOpen);
									}
								}}
							>
								Add Request
							</Button>
						</Link>
					)}

					{enableAddChallenge && (
						<Link href={'/dashboard/challenges/create'}>
							<Button
								size={ButtonSize.sm}
								buttonProps={{
									className: cc([
										'add-challenge-button',
										{
											'w-full justify-center': display === 'mobile',
										},
									]),
								}}
								onClick={() => {
									track(
										TrackEvent.NavigationAddChallengeButtonClicked,
										defaultTrackingMetadata,
									);
									if (display === 'mobile') {
										setMobileMenuIsOpen(!mobileMenuIsOpen);
									}
								}}
							>
								Add Challenge
							</Button>
						</Link>
					)}
				</div>
			</div>
		</>
	);
};
