export const returnDefaultLocale = (): string => {
	if (process.env.NEXT_PUBLIC_LOCALE === undefined) {
		return 'en-US';
	} else {
		return process.env.NEXT_PUBLIC_LOCALE;
	}
};

export const returnDefaultLocalePhoneInputForClient = (): string => {
	if (process.env.NEXT_PUBLIC_APP_SITE === 'opennation') {
		return 'GB';
	} else {
		return 'US';
	}
};
