import { useME } from '@theme/hooks/useMe';
import * as React from 'react';
import { useRouter } from 'next/router';
import {
	load as loadIntercom,
	boot as bootIntercom,
	update as updateIntercom,
} from './intercom';
import { useSession } from 'next-auth/react';

export const IntercomProvider = ({ children }) => {
	const router = useRouter();
	// Valid if the url comes from a team member invitation
	const isTeamMemberInvite =
		router.pathname.startsWith('/team-invite') ||
		!!router.query?.returnUrl?.includes('team_invite_');

	// Validates if the route is of type signup
	const signupRoute = router.pathname !== '/signup' && !isTeamMemberInvite;

	const hasCompanySession =
		router.pathname !== '/login/workspace' &&
		router.pathname !== '/login' &&
		signupRoute &&
		router.pathname !== '/'
			? true
			: false;

	const { data } = useME(hasCompanySession, 'cache-first');

	const { data: session } = useSession();

	React.useEffect(() => {
		let userInfo;

		if (session && data?.me?.hmac) {
			userInfo = {
				user_id: session ? session.user.id : null,
				user_hash: data?.me?.hmac ? data?.me?.hmac : null,
			};

			const options = {
				api_base: 'https://api-iam.intercom.io',
				app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
				...userInfo,
			};

			if (
				typeof window !== 'undefined' &&
				process.env.NEXT_PUBLIC_DISPLAY_INTERCOM === 'true'
			) {
				loadIntercom();
				bootIntercom(options);
			}
		}
	}, [data, session]);

	React.useEffect(() => {
		const handleRouteChange = () => {
			if (typeof window !== 'undefined') {
				updateIntercom();
			}
		};

		router.events.on('routeChangeStart', handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router.events]);

	return children;
};
