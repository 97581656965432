import * as React from 'react';
import Icons from '../../public/img/icons.svg';
import { theme } from '../../utils/theme';

export interface Props {
	name?: string;
	className?: string;
	color?: string;
	stroke?: string;
	size?: string;
	[svgProps: string]: React.ComponentProps<'svg'> | string;
}

// Icon can get direct items like color, stroke and size or just tailwind classes in className tag
const Icon = (props: Props) => {
	const { name, color, stroke, size, className, ...rest } = props;

	if (name === 'inbox-new') {
		return <IconInboxNew />;
	} else if (name === 'meeting-new') {
		return <IconMeetingNew />;
	} else if (name === 'meetings') {
		return <IconMeetings />;
	} else if (name === 'itemsadd32') {
		return <IconItemsAdd32 />;
	} else if (name === 'arrow-right') {
		return <IconArrowRight />;
	} else {
		return (
			<svg
				fill={color}
				stroke={stroke}
				width={size}
				height={size}
				className={className}
				{...rest}
			>
				<use href={`${Icons}#${name}`} />
			</svg>
		);
	}
};

export default Icon;

const IconInboxNew = () => {
	return (
		<svg
			className={'h-6 w-6 fill-skin-icon stroke-current text-gray-500'}
			viewBox="0 0 24 24"
		>
			<rect
				x="3"
				y="5"
				width="18"
				height="14"
				rx="1"
				fill="none"
				strokeWidth="2"
			/>
			<path d="M21 5L12 14L3 5" strokeWidth="2" fill="none" />
			<circle
				cx="20"
				cy="4"
				r="4"
				stroke={theme.fill['skin-icon']}
				fillRule="inherit"
			/>
		</svg>
	);
};

const IconMeetingNew = () => {
	return (
		<svg
			className={'h-6 w-6 fill-skin-icon stroke-current text-gray-500'}
			viewBox="0 0 24 24"
		>
			<rect
				x="3"
				y="6"
				width="18"
				height="14"
				rx="1"
				strokeWidth="2"
				fill="none"
			/>
			<path d="M7 3V8" strokeWidth="2" fill="none" />
			<path d="M12 3V8" strokeWidth="2" fill="none" />
			<path d="M17 3V8" strokeWidth="2" fill="none" />
			<circle
				cx="20"
				cy="4"
				r="4"
				stroke={theme.fill['skin-icon']}
				fillRule="inherit"
			/>
			<path d="M4 11H20" strokeWidth="2" fill="none" />
		</svg>
	);
};

const IconMeetings = () => {
	const strokeColor = theme.textColor.gray[500];

	return (
		<svg
			className={'h-6 w-6 fill-transparent stroke-current'}
			viewBox="0 0 24 24"
		>
			<rect
				x="3"
				y="6"
				width="18"
				height="14"
				rx="1"
				stroke={strokeColor}
				strokeWidth="2"
			/>
			<path d="M7 3V8" stroke={strokeColor} strokeWidth="2" />
			<path d="M12 3V8" stroke={strokeColor} strokeWidth="2" />
			<path d="M17 3V8" stroke={strokeColor} strokeWidth="2" />
			<path d="M4 11H20" stroke={strokeColor} strokeWidth="2" />
			<circle
				cx="17.5"
				cy="17.5"
				r="6.5"
				fill={theme.fill['skin-icon']}
				stroke={theme.fill['skin-icon']}
			/>
			<path
				d="M15.2656 17.7031L16.8906 19.3281L20.1405 16.0782"
				stroke="white"
				strokeLinecap="square"
			/>
		</svg>
	);
};

const IconItemsAdd32 = () => {
	const fillColor = theme.fill['skin-icon'];
	return (
		<svg
			className={'h-10 w-10 fill-transparent stroke-current text-gray-500'}
			viewBox="0 0 32 32"
		>
			<path
				d="M7.97412 13.8263C8.0642 12.793 8.92931 12 9.96656 12H21.0334C22.0707 12 22.9358 12.793 23.0259 13.8263L23.8105 22.8263C23.9124 23.995 22.9912 25 21.8181 25H9.18195C8.00883 25 7.08762 23.995 7.1895 22.8263L7.97412 13.8263Z"
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<path
				d="M19 14V10.4286C19 8.53502 17.433 7 15.5 7C13.567 7 12 8.53502 12 10.4286V14"
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<circle cx="24" cy="24" r="8" fill={fillColor} stroke={fillColor} />
			<path
				d="M25.2243 21V28H23.7843V23.53C23.6976 23.5633 23.571 23.58 23.4043 23.58H22.2343V22.39H23.2843C23.4576 22.39 23.5876 22.3233 23.6743 22.19C23.7676 22.05 23.8143 21.88 23.8143 21.68V21H25.2243Z"
				fill="white"
			/>
		</svg>
	);
};

const IconArrowRight = () => {
	return (
		<svg
			className={'h-6 w-6 fill-skin-icon stroke-current text-skin-base-icon'}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 13V16L20 12.5L16 9V12H3V13H16Z"
			/>
		</svg>
	);
};
