import { useReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';

import {
	Notifications,
	addNotification,
} from '@vintro/components/vintro/notifications/index';

export const notificationObj = makeVar({
	messages: null,
	displayTime: 4000,
});

export const addNotificationMessage = (textMessage) => {
	addNotification(notificationObj, textMessage);
};

export const changeNotificationTime = (time) => {
	notificationObj({
		...notificationObj(),
		displayTime: time,
	});
};

export const GlobalNotifications: React.FC = () => {
	const notificationContent = useReactiveVar(notificationObj);

	return (
		<Notifications
			notificationObj={notificationObj}
			messages={notificationContent.messages}
			displayTime={notificationContent.displayTime}
		/>
	);
};
