import { PropsWithChildren } from 'react';
import * as React from 'react';
import {
	defaultTrackingMetadata,
	IntersectionEvent,
} from '@theme/analytics/analytics';
import { useInView } from 'react-intersection-observer';
import { useAnalytics } from 'use-analytics';

interface AnalyticsTrackIntersectionEventWrapperProps
	extends PropsWithChildren {
	data;
	eventName: IntersectionEvent;
	position?: number; // position of item in list or view
	triggerOnce?: boolean; // trigger event fire once
	threshold?: number; // percentage of element that needs to be visible before event is fired - from 0 (none) to 1 (complete)
}

export const AnalyticsTrackIntersectionEventWrapper: React.FC<AnalyticsTrackIntersectionEventWrapperProps> = (
	props,
) => {
	const { children, position, data, eventName, triggerOnce, threshold } = props;
	const { track } = useAnalytics();

	const { ref } = useInView({
		triggerOnce: triggerOnce ? triggerOnce : true,
		threshold: threshold ? threshold : 1,
		onChange: (inView) => {
			if (inView) {
				track(eventName, {
					data: data,
					viewPosition: position,
					...defaultTrackingMetadata.data,
				});
			}
		},
	});

	return <div ref={ref}>{children}</div>;
};
