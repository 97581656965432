import { NavigationPrimaryNotifications } from '@/components/navigation/components/notifications';
import * as React from 'react';
import { NavigationActions } from '@/components/navigation/components/navigation-actions';
import { NavigationLogoPrimary } from '@/components/navigation/components/navigation-logo';
import { NavigationUserInfoAndMenu } from '@/components/navigation/components/navigation-user-info-and-menu';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import {
	Navbar,
	NavbarSection,
	NavbarSectionItem,
} from '@vintro/components/vintro/navigation/navbar/navbar';
import { useScreenBreakpoint } from '@vintro/components/utils/hooks/useScreenBreakpoint';

import {
	NavigationLinks,
	Source,
} from '@theme/components/navigation/components/navigation-links';

export const NavigationPrimary = (props) => {
	const { activeNavigationItem } = props;
	const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState(false);
	const screen = useScreenBreakpoint();

	return (
		<>
			<Navbar id="navigation-primary">
				<NavbarSection>
					<NavbarSectionItem>
						<>
							{screen === 'sm' && (
								<button
									type="button"
									className="mobile-menu-button -ml-4 mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-skin-button-base hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
									aria-controls="mobile-menu"
									aria-expanded="false"
									onClick={() => {
										setMobileMenuIsOpen(!mobileMenuIsOpen);
									}}
								>
									<span className="sr-only">Open main menu</span>
									{mobileMenuIsOpen ? (
										<XIcon className="h-6 w-6" />
									) : (
										<MenuIcon className="h-6 w-6" />
									)}
								</button>
							)}
							<NavigationLogoPrimary />
						</>
					</NavbarSectionItem>
				</NavbarSection>
				<NavbarSection>
					<NavbarSectionItem>
						<NavigationUserInfoAndMenu />
						<NavigationActions display={'desktop'} />
						<span className="ml-6 mr-6 hidden h-8 w-px bg-neutral-600 sm:block"></span>
						<NavigationPrimaryNotifications />
					</NavbarSectionItem>
				</NavbarSection>
			</Navbar>
			{mobileMenuIsOpen && (
				<NavigationMobileMenu
					activeNavigationItem={activeNavigationItem}
					setMobileMenuIsOpen={setMobileMenuIsOpen}
					mobileMenuIsOpen={mobileMenuIsOpen}
				/>
			)}
		</>
	);
};

export const NavigationMobileMenu = (props) => {
	const { activeNavigationItem, setMobileMenuIsOpen, mobileMenuIsOpen } = props;

	return (
		<div
			className="border-b bg-skin-navbar-mobile-menu-base md:hidden"
			id="mobile-menu"
		>
			<div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
				<NavigationLinks
					active={activeNavigationItem}
					source={Source.primary}
					onLinkClick={() => {
						setMobileMenuIsOpen(!mobileMenuIsOpen);
					}}
				/>
			</div>
			<NavigationActions
				display={'mobile'}
				setMobileMenuIsOpen={setMobileMenuIsOpen}
				mobileMenuIsOpen={mobileMenuIsOpen}
			/>
		</div>
	);
};

export default NavigationPrimary;
