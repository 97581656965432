import { hasCookie, deleteCookie } from 'cookies-next';

export const COOKIES = {
	VERTICALS: 'vintro.verticals',
	USER_ROLES: 'vintro.userRoles',
};

export const removeCookies = (): void => {
	Object.values(COOKIES).forEach((cookie) => {
		if (hasCookie(cookie)) {
			deleteCookie(cookie);
		}
	});
};
