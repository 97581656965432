import * as React from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@vintro/components/tailwind.config.js';
const fullConfig = resolveConfig(tailwindConfig);

export const calculateBreakpointSize = () => {
	if (
		window.innerWidth <= parseInt(fullConfig.theme.screens.sm.split('px')[0])
	) {
		return 'sm';
	} else if (
		window.innerWidth <= parseInt(fullConfig.theme.screens.md.split('px')[0])
	) {
		return 'md';
	} else if (
		window.innerWidth <= parseInt(fullConfig.theme.screens.lg.split('px')[0])
	) {
		return 'lg';
	} else if (
		window.innerWidth > parseInt(fullConfig.theme.screens.lg.split('px')[0])
	) {
		return 'xl';
	}
};

export const useScreenBreakpoint = () => {
	const [screenBreakpoint, setScreenBreakpoint] = React.useState(null);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenBreakpoint(calculateBreakpointSize());
		};

		window.addEventListener('resize', handleResize);
		handleResize();
	}, []);

	return screenBreakpoint;
};
