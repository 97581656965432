import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const useME = (
	hasCompanySession: boolean,
	fetchPolicy: 'cache-first' | 'cache-and-network' | 'cache-only',
) => {
	const { data, loading, error } = useQuery(COMBINED_ME_CALLS, {
		variables: {
			hasCompanySession: hasCompanySession,
		},
		fetchPolicy: fetchPolicy,
	});

	const isCompanyOwner = data?.me?.id === data?.myCompany?.owner?.id;

	return {
		data,
		loading,
		error,
		isCompanyOwner,
	};
};

/*
 * Export the COMBINED_ME_CALLS for use in refetchQueries - in update on Account and Communication Preferences
 */
export const COMBINED_ME_CALLS = gql`
	fragment TeamMemberFragment on User {
		teamMember {
			id
			avatarUrl
			myUnreadChatMessagesCount
			sendbirdAccessToken
			roles
			updatedAt
			company {
				id
				name
				avatarUrl
			}
			communicationPreference {
				chatNotificationEmail
				dailyMatchDigestEmail
				updatedAt
			}
		}
	}

	fragment MyCompanyFragment on Company {
		id
		dunsVerified
		name
		owner {
			id
		}
		verticals
		categories {
			id
			name
			level
			segment {
				name
			}
		}
	}

	query COMBINED_ME_CALLS($hasCompanySession: Boolean!) {
		me {
			id
			email
			phone
			firstName
			lastName
			hmac
			preferredTimezone
			updatedAt
			companies {
				id
				name
			}
			...TeamMemberFragment @include(if: $hasCompanySession)
		}
		myCompany @include(if: $hasCompanySession) {
			...MyCompanyFragment
		}
	}
`;
