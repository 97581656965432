import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';
import { CheckIcon } from '@heroicons/react/outline';
import { BackgroundColor, RingColor, TextColor } from '../global/enum';

export enum AvatarItemSize {
	sm = 'sm',
	lg = 'lg',
}

interface AvatarsProps extends PropsWithChildren {
	className?: string;
}

interface AvatarItemSharedProps extends PropsWithChildren {
	className?: string;
	size?: AvatarItemSize;
	backgroundColor?: BackgroundColor;
	textColor?: TextColor;
	ringColor?: RingColor | string;
	depth?: 1 | 2 | 3 | 4 | 5; // 1 is top - 5 is bottom
}

interface AvatarsItemProps extends AvatarItemSharedProps {
	src: string;
	alt?: string;
	checked?: boolean;
	checkmarkProps?: {
		backgroundColor?: BackgroundColor;
		textColor?: TextColor;
		ringColor?: RingColor;
	};
}

const returnAvatarDepth = (depth: AvatarItemSharedProps['depth']) => {
	switch (depth) {
		case 2:
			return 'z-40';
		case 3:
			return 'z-30';
		case 4:
			return 'z-20';
		case 5:
			return 'z-10';
		case 1:
		default:
			return 'z-50';
	}
};

export const DataAvatars: React.FC<AvatarsProps> = (props) => {
	const { children, className } = props;

	return (
		<div className={cc(['relative z-0 flex shrink-0 -space-x-1', className])}>
			{children}
		</div>
	);
};

export const DataAvatarsItem: React.FC<AvatarsItemProps> = (props) => {
	const {
		alt,
		src,
		className,
		checked,
		checkmarkProps = {
			backgroundColor: BackgroundColor.primary,
			ringColor: RingColor.white,
			textColor: TextColor.white,
		},
		ringColor,
		size = AvatarItemSize.lg,
		depth,
	} = props;

	return (
		<span
			className={cc([
				'relative flex w-auto shrink-0',
				{
					'h-10': size === AvatarItemSize.lg,
					'h-6': size === AvatarItemSize.sm,
				},
			])}
		>
			<img
				className={cc([
					'data-avatars-item-image relative inline-block rounded-full object-cover text-xs ring-1',
					returnAvatarDepth(depth),
					{
						'h-10 w-10': size === AvatarItemSize.lg,
						'h-6 w-6': size === AvatarItemSize.sm,
					},
					ringColor,
					className,
				])}
				src={src}
				alt={alt}
			/>
			{checked && (
				<div
					className={cc([
						checkmarkProps.backgroundColor,
						checkmarkProps.ringColor,
						checkmarkProps.textColor,
						returnAvatarDepth(depth),
						{
							'-left-1 h-4 w-4 ring-2': size === AvatarItemSize.lg,
							'-left-0.5 h-2 w-2 ring-1': size === AvatarItemSize.sm,
						},
						'absolute bottom-0 z-50 block rounded-full',
					])}
				>
					<CheckIcon className={cc(['h-full w-full'])} />
				</div>
			)}
		</span>
	);
};

export const DataAvatarsCheck: React.FC<AvatarItemSharedProps> = (props) => {
	const {
		ringColor,
		size = AvatarItemSize.lg,
		backgroundColor = BackgroundColor.primary,
		textColor = TextColor.white,
		depth,
	} = props;

	return (
		<div
			className={cc([
				'relative flex max-w-max items-center justify-center rounded-full ring-1',
				backgroundColor,
				textColor,
				ringColor,
				returnAvatarDepth(depth),
			])}
		>
			<CheckIcon
				className={cc([
					{
						'h-10': size === AvatarItemSize.lg,
						'h-6': size === AvatarItemSize.sm,
					},
				])}
			/>
		</div>
	);
};

export const DataAvatarsLabel: React.FC<AvatarItemSharedProps> = (props) => {
	const {
		children,
		ringColor = RingColor.white,
		size = AvatarItemSize.lg,
		backgroundColor = BackgroundColor.black,
		textColor = TextColor.white,
		className,
	} = props;

	return (
		<div
			className={cc([
				'relative z-0 flex max-w-full shrink-0 items-center justify-center rounded-full px-1 ring-1 lg:px-3',
				ringColor,
				backgroundColor,
				textColor,
				{
					'h-10 w-10 text-base': size === AvatarItemSize.lg,
					'h-6 w-6 text-xs': size === AvatarItemSize.sm,
				},
				className,
			])}
		>
			{children}
		</div>
	);
};
