import * as React from 'react';
import { FeatureToggleContext } from '../context/feature-toggle-context';
import { FeatureFlag, featureFlags } from '@theme/theme/features/feature-flags';

export const useFeatureToggle = () => {
	const { enabledFeatures } = React.useContext(FeatureToggleContext);

	const isEnabled = (featureName: FeatureFlag) => {
		return enabledFeatures.includes(featureName);
	};

	return [isEnabled];
};

export const enabledFeatures = Object.keys(featureFlags)
	.map((key) => {
		if (featureFlags[key] === true) {
			return key;
		} else {
			return;
		}
	})
	.filter((i) => {
		return i !== undefined;
	});
