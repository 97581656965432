import * as React from 'react';

export const EnvBanner = () => {
	return (
		<>
			{process.env.NEXT_PUBLIC_APP_SITE === 'vintro-dev' && (
				<div className="bg-orange-500">
					<div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
						<div className="flex flex-wrap items-center justify-between">
							<div className="flex w-0 flex-1 items-center justify-center">
								<p className="ml-3 truncate font-medium text-white">
									You are using - {process.env.NEXT_PUBLIC_APP_SITE} - set
									feature flags at
									`packages/app/theme/features/vintro-dev/features.json`
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
