import { Copyright } from '@/components/copyright/copyright';
import { LandingSocialLinks } from '@/components/social-links/social-links';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { useRouter } from 'next/router';
import { getSession } from 'next-auth/react';
import cc from 'classcat';
import { useScreenBreakpoint } from '@vintro/components/utils/hooks/useScreenBreakpoint';
import { Panel } from '@vintro/components/vintro/panels/panels';
import { EnvBanner } from '@/components/debug/env-banner';

export interface LayoutAuthProps extends PropsWithChildren {
	className?: string;
}

interface LayoutAuthFooterInfoProps {
	screenSize: 'mobile' | 'desktop';
}

const layoutContainerClassName = 'min-h-screen relative bg-cover bg-center';
const layoutContainerContentClassName =
	'relative min-h-screen w-full mx-auto flex flex-col items-center justify-center sm:p-20 md:p-4 md:max-w-7xl md:justify-between md:flex-row max-w-7xl xl:max-w-none xl:w-2/3';
const layoutContentLogoContainerClassName =
	'w-full flex items-center justify-center md:h-full';
const layoutContentLogoClassName =
	'h-24 w-auto p-6 md:max-w-xl mb-5 md:mb-0 filter drop-shadow-lg md:h-32 lg:h-36';
const layoutContentFooterContainerClassName = 'absolute text-white';
const layoutFooterPositionRight = 'right-24 bottom-10';
const layoutFooterPositionLeft = 'left-10 bottom-10';

const VINTRO_LOGO = '/img/vintro-logo.svg';
const VINTRO_BACKGROUND_IMAGE = '/img/login.full.png';
const DNB_LOGO = '/img/dnb-auth-logo.svg';
const DNB_BACKGROUND_IMAGE = '/img/login.dnb.jpeg';
const OPENNATION_LOGO = '/img/opennation-logo.svg';
const OPENNATION_BACKGROUND_IMAGE = '/img/login.full.png';

export const LayoutStyledAuth: React.FC<LayoutAuthProps> = (props) => {
	const router = useRouter();
	const { children } = props;
	const screen = useScreenBreakpoint();
	const [logo, setLogo] = React.useState(null);
	const [backgroundImage, setBackgroundImage] = React.useState(null);
	const [readyToRender, setReadyToRender] = React.useState(false);

	const shouldHandleRedirect =
		router.pathname === '/' || router.pathname === '/login';

	React.useEffect(() => {
		// Set background image
		if (
			document.getElementById('app-theme-container').getAttribute('class') &&
			document
				.getElementById('app-theme-container')
				.getAttribute('class')
				.includes('dnb')
		) {
			setLogo(DNB_LOGO);
			setBackgroundImage(DNB_BACKGROUND_IMAGE);
		} else if (
			document.getElementById('app-theme-container').getAttribute('class') &&
			document
				.getElementById('app-theme-container')
				.getAttribute('class')
				.includes('opennation')
		) {
			setLogo(OPENNATION_LOGO);
			setBackgroundImage(OPENNATION_BACKGROUND_IMAGE);
		} else {
			setLogo(VINTRO_LOGO);
			setBackgroundImage(VINTRO_BACKGROUND_IMAGE);
		}
	}, []);

	React.useEffect(() => {
		const getAsyncSession = async () => {
			const session = await getSession();

			if (session?.company?.id) {
				return router.push('/dashboard');
			}

			if (session?.company?.id === null) {
				return router.push('/login/workspace');
			}

			setReadyToRender(true);
		};

		if (shouldHandleRedirect) {
			getAsyncSession();
		}
	}, [shouldHandleRedirect, router]);

	// display a blank page until we have the session info and know if we should redirect or display the current page
	// avoids displaying momentarily the layout page with empty content in the panel (to then redirecting to the right page)
	if (shouldHandleRedirect && !readyToRender) {
		return null;
	}

	return (
		<div
			className={cc([layoutContainerClassName])}
			style={
				backgroundImage
					? {
							backgroundImage: `url(${backgroundImage})`,
					  }
					: null
			}
		>
			<EnvBanner />
			<div className={cc([layoutContainerContentClassName])}>
				<div className={cc([layoutContentLogoContainerClassName])}>
					<img
						className={cc([layoutContentLogoClassName])}
						src={logo}
						alt="Application logo"
					/>
				</div>
				<Panel className={'min-h-1/2 w-3/4'}>{children}</Panel>

				{screen === 'sm' && <LayoutAuthFooterInfo screenSize="mobile" />}
			</div>
			{screen !== 'sm' && <LayoutAuthFooterInfo screenSize="desktop" />}
		</div>
	);
};

export const LayoutAuthFooterInfo: React.FC<LayoutAuthFooterInfoProps> = (
	props,
) => {
	const { screenSize } = props;

	return (
		<div
			className={cc([
				{
					'my-3 flex flex-col items-center text-white': screenSize === 'mobile',
				},
			])}
		>
			<Copyright
				positionClasses={layoutFooterPositionLeft}
				screenSize={screenSize}
				containerClasses={layoutContentFooterContainerClassName}
			/>
			<LandingSocialLinks
				positionClasses={layoutFooterPositionRight}
				screenSize={screenSize}
				containerClasses={layoutContentFooterContainerClassName}
			/>
		</div>
	);
};
