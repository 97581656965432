export enum AppSiteAwareTextKeys {
	'landing.sprinkler.anchor' = 'landing.sprinkler.anchor',
	'landing.sprinkler.body.heading' = 'landing.sprinkler.body.heading',
	'landing.sprinkler.page.heading' = 'landing.sprinkler.page.heading',
	'signin.form.heading' = 'signin.form.heading',
	'signup.form.heading' = 'signup.form.heading',
	'copyright.text' = 'copyright.text',
}

export type DynamicValueKeys = '|copyrightDate|';
