import { UserMenuOptions } from '@/components/navigation/components/navigation-user-menu-options';
import {
	defaultTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import { useME } from '@theme/hooks/useMe';
import {
	NavbarProfile,
	NavbarProfileName,
} from '@vintro/components/vintro/navigation/navbar/navbar';
import TextButton, {
	TextButtonType,
} from '@vintro/components/vintro/textbutton';
import UserMenu, { UserMenuPanel } from '@vintro/components/vintro/user-menu';
import cc from 'classcat';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useAnalytics } from 'use-analytics';

export const NavigationUserInfoAndMenu = () => {
	const { track } = useAnalytics();
	const { data, loading } = useME(true, 'cache-first');

	const router = useRouter();
	const [menuOpen, setMenuOpen] = React.useState(false);

	if (loading) return null;

	const currentPath = router.pathname;

	const firstName = data?.me?.firstName;
	const company = data?.me?.teamMember?.company?.name;
	const avatarUrl = data?.me?.teamMember?.avatarUrl;

	const toggleMenu = (e) => {
		track(TrackEvent.NavigationUserMenuOpened, defaultTrackingMetadata);
		e.stopPropagation();
		setMenuOpen(!menuOpen);
	};

	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<>
			<UserMenu>
				<NavbarProfile
					className={cc(['flex'])}
					src={avatarUrl || '/img/default-avatar.png'}
					alt="Profile Image"
					isOpen={menuOpen}
					onClick={toggleMenu}
				/>

				<UserMenuPanel
					isOpen={menuOpen}
					currentMenuItem={currentPath}
					onClose={closeMenu}
					className={'user-menu-panel'}
				>
					<UserMenuOptions
						currentPath={currentPath}
						closeUserMenu={closeMenu}
					/>
				</UserMenuPanel>
			</UserMenu>

			{firstName && company && (
				<NavbarProfileName>
					<TextButton
						className="navbar-menu-button"
						onClick={toggleMenu}
						type={TextButtonType.navbar}
					>
						{firstName} / {company}
					</TextButton>
				</NavbarProfileName>
			)}
		</>
	);
};
