import { Copyright } from '@/components/copyright/copyright';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { AnalyticsTrackIntersectionEventWrapper } from '@/components/analytics-track-intersection';
import { useME } from '@theme/hooks/useMe';
import {
	defaultTrackingMetadata,
	IntersectionEvent,
	TrackEvent,
	VerifyBannerTrackingMetadata,
} from '@theme/analytics/analytics';
import cc from 'classcat';
import NavigationPrimary from '@/components/navigation/primary';
import {
	Banner,
	BannerAction,
	BannerMessage,
} from '@vintro/components/vintro/banner';
import { useReactiveVar } from '@apollo/client';
import { useScreenBreakpoint } from '@vintro/components/utils/hooks/useScreenBreakpoint';
import { navHeightVar } from '../../../state/layout';
import { EnvBanner } from '@/components/debug/env-banner';
import { useAnalytics } from 'use-analytics';
import { useRouter } from 'next/router';

import Sidebar from '@theme/../components/vintro/sidebar';
import {
	NavigationLinks,
	Source,
} from '@theme/components/navigation/components/navigation-links';
interface LayoutContentProps extends PropsWithChildren {
	navHeight: string;
	activeSidebarItem: string;
	fullScreen: boolean;
}
interface LayoutDashboardProps extends PropsWithChildren {
	active?: string;
	fullScreen?: boolean;
}

interface LayoutDashboardMenuProps {
	active?: string;
	hideSideMenu?: boolean;
}

export const LayoutDashboardMenu: React.FC<LayoutDashboardMenuProps> = (
	props,
) => {
	const { active, hideSideMenu } = props;

	if (hideSideMenu) {
		return null;
	}

	return (
		<Sidebar>
			<NavigationLinks active={active} source={Source.dashboard} />
		</Sidebar>
	);
};

export const LayoutContent: React.FC<LayoutContentProps> = (props) => {
	const { navHeight, activeSidebarItem, children, fullScreen } = props;
	const screen = useScreenBreakpoint();

	return (
		<div
			className="flex"
			style={{
				height: `calc(100vh - ${navHeight})`,
			}}
		>
			<div className="flex min-w-0 flex-1 flex-row overflow-hidden">
				{screen !== 'sm' && (
					<div>
						<div
							className={cc([
								'relative w-full overflow-hidden bg-skin-sidebar-base sm:overflow-auto',
							])}
						>
							<div
								style={{
									height: `calc(100vh - ${navHeight})`,
								}}
							>
								<LayoutDashboardMenu
									active={activeSidebarItem}
									hideSideMenu={fullScreen}
								/>
							</div>
						</div>
					</div>
				)}

				<div className={cc(['relative flex flex-1 overflow-hidden'])}>
					<main className={cc(['relative flex-1 overflow-y-auto'])}>
						<div
							className={cc([
								'absolute inset-0',
								{ 'mx-auto max-w-7xl': !fullScreen },
							])}
						>
							<div
								className={cc([{ 'py-6 px-4 sm:px-6 lg:px-8': !fullScreen }])}
							>
								{children}
								{!fullScreen && <Copyright inline={true} />}
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export const LayoutDashboard: React.FC<LayoutDashboardProps> = (props) => {
	const router = useRouter();
	const { track } = useAnalytics();
	const { active, fullScreen = false } = props;
	const navHeight = useReactiveVar(navHeightVar);
	const { data, loading, isCompanyOwner } = useME(true, 'cache-first');

	const showVerifiedBanner =
		data?.myCompany?.dunsVerified === false && isCompanyOwner;

	React.useEffect(() => {
		const navHeight = `${
			document.getElementById('nav-containers').offsetHeight
		}px`;

		navHeightVar(navHeight);
	}, [loading]);

	React.useEffect(() => {
		if (router?.isReady && !loading && !data?.myCompany) {
			router.push('/login/workspace');
		}
	}, [loading, data, router]);

	const trackingMetadata: VerifyBannerTrackingMetadata = {
		data: {
			...data?.myCompany,
			...defaultTrackingMetadata.data,
		},
	};

	return (
		<div className="layout-dashboard min-h-screen bg-skin-page">
			<div id="nav-containers">
				<EnvBanner />
				{showVerifiedBanner && (
					<AnalyticsTrackIntersectionEventWrapper
						data={data?.myCompany}
						eventName={IntersectionEvent.VerifyBannerShown}
					>
						<div id="verify-banner">
							<Banner isDismissible={false}>
								<BannerMessage>
									Please verify your company information.
								</BannerMessage>
								<BannerAction
									linkUrl={`/signup/journey/buyer/company?companyId=${data?.myCompany?.id}`}
									onClick={() => {
										track(
											TrackEvent.VerifyBannerVerifyButtonClicked,
											trackingMetadata,
										);
									}}
								>
									Verify
								</BannerAction>
							</Banner>
						</div>
					</AnalyticsTrackIntersectionEventWrapper>
				)}
				<NavigationPrimary activeNavigationItem={active} />
			</div>
			<div>
				<LayoutContent
					navHeight={navHeight}
					activeSidebarItem={active}
					fullScreen={fullScreen}
				>
					{props.children}
				</LayoutContent>
			</div>
		</div>
	);
};
