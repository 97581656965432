import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';
import * as OutlineIcons from '@heroicons/react/outline';

import Icon from '../icon';

interface SidebarProps {
	children: React.ReactNode;
}

interface SidebarItemProps extends PropsWithChildren {
	icon: string;
	label: string;
	isSelected: boolean;
	disabled?: boolean;
	showNotification?: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	className?: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = (props) => {
	const {
		icon,
		label,
		isSelected,
		onClick,
		disabled,
		children,
		showNotification,
		className,
	} = props;

	const HeroIcon = OutlineIcons[icon];

	return (
		<button
			className={cc([
				className,
				'group relative flex h-14 w-full items-center rounded-none border-r-4 border-transparent px-6 hover:border-skin-base sm:h-16',
				{
					'border-skin-base': isSelected,
					'cursor-not-allowed opacity-60': disabled,
				},
			])}
			onClick={onClick}
			aria-label={label}
			disabled={disabled}
		>
			{HeroIcon ? (
				<HeroIcon
					className={cc([
						'group-hover:opacity-60',
						'h-5 w-5 sm:h-6 sm:w-6',
						'stroke-current',
						{
							'text-skin-sidebar-icon-selected': isSelected,
							'text-skin-sidebar-icon-unselected': !isSelected,
						},
					])}
				/>
			) : (
				<Icon
					className={cc([
						'group-hover:opacity-60',
						'h-5 w-5 sm:h-6 sm:w-6',
						'stroke-current',
						{
							'text-skin-sidebar-icon-selected': isSelected,
							'text-skin-sidebar-icon-unselected': !isSelected,
						},
					])}
					name={icon}
				/>
			)}
			<span
				className={cc([
					'ml-4 font-headline group-hover:opacity-60',
					{
						'text-skin-sidebar-text-selected': isSelected,
						'text-skin-sidebar-text-unselected': !isSelected,
					},
				])}
			>
				{children}
			</span>

			{showNotification && (
				<span className="chat-notification-icon absolute right-4 h-2 w-2 rounded-full bg-skin-base"></span>
			)}
		</button>
	);
};

const Sidebar: React.FC<SidebarProps> = (props) => {
	const { children } = props;

	return (
		<div className="container-shadow h-full flex-col overflow-auto bg-skin-sidebar-base px-0 sm:py-6">
			{children}
		</div>
	);
};

export default Sidebar;
