export const returnPageTitle = (): string => {
	// The production build needs the env var to be cast to string explicitly
	const appSite: string = (process.env.NEXT_PUBLIC_APP_SITE || '').toString();

	if (appSite === 'dnb') {
		return 'D&B Supplier Match';
	} else if (appSite === 'opennation') {
		return 'Open Nation';
	} else {
		return 'Vintro';
	}
};
