import * as React from 'react';
import Icon from '../icon/index';

interface Props {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MenuButton = (props: Props) => {
	const { onClick } = props;

	const [menuButtonIsOpen, setMenuButtonIsOpen] = React.useState(false);

	const menuClick = (e) => {
		menuButtonToggle();
		// Custom on click that comes from props
		onClick(e);
	};

	const menuButtonToggle = (): void => {
		setMenuButtonIsOpen(!menuButtonIsOpen);
	};

	return (
		<button
			onClick={menuClick}
			className={
				'flex h-12 w-12 items-center justify-center overflow-hidden rounded-sm bg-skin-nav-menu-button text-center text-skin-base'
			}
			aria-label="toggle menu"
		>
			<Icon
				name={menuButtonIsOpen ? 'close24' : 'hamburger'}
				className="h-6 w-6 stroke-current text-skin-nav-menu-button"
			/>
		</button>
	);
};

export default MenuButton;
