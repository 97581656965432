import cc from 'classcat';
import * as React from 'react';
import { LayoutAuthProps } from '@/components/layouts/auth/auth';

export const LayoutWorkspace: React.FC<LayoutAuthProps> = (props) => {
	const { className, children } = props;

	return (
		<div className="layout-auth mt-20 mb-4">
			<div className={cc([className, 'mx-auto'])}>{children}</div>
		</div>
	);
};
