import {
	defaultTrackingMetadata,
	MenuTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import { useME } from '@theme/hooks/useMe';
import * as React from 'react';
import { useRouter } from 'next/router';
import cc from 'classcat';

import { SidebarItem } from '@theme/../components/vintro/sidebar';
import navigationLinks, {
	renderFeatureFlaggedNavigationLinks,
} from '@theme/components/data/navigation-links';
import { useCompanyVerticals } from '@theme/hooks/useCompanyVerticals';
import { useFeatureToggle } from '@theme/hooks/useFeatureToggle';
import Link from 'next/link';
import { useAnalytics } from 'use-analytics';

export enum Source {
	dashboard = 'dashboard',
	primary = 'primary',
}

interface NavigationLinksProps {
	active: string;
	source: Source;
	onLinkClick?: () => void;
}

export const NavigationLinks: React.FC<NavigationLinksProps> = (props) => {
	const { track } = useAnalytics();
	const { data } = useME(true, 'cache-first');

	const { active, source, onLinkClick } = props;
	const router = useRouter();
	const [isEnabled] = useFeatureToggle();
	const {
		isRequestCreator,
		isChallengeCreator,
		isChallengeResponder,
	} = useCompanyVerticals();

	const unreadChatMessages =
		data?.me?.teamMember?.myUnreadChatMessagesCount ?? 0;

	const showNotificationIcon = (keyName: string) => {
		switch (keyName) {
			case 'chat':
				return unreadChatMessages > 0;
			default:
				return false;
		}
	};

	const showNotificationDefinitions = {
		chat: unreadChatMessages,
	};

	const renderNavigationLinks = (i, index) => {
		if (source === Source.dashboard) {
			return (
				<SidebarItem
					key={index}
					label={i.displayText}
					icon={i.icon}
					isSelected={active === i.key}
					onClick={() => {
						track(TrackEvent[i.clickEventKey], {
							data: {
								location: 'sidemenu',
								previousURL: router.pathname,
								...defaultTrackingMetadata.data,
							},
						} as MenuTrackingMetadata);
						router.push(i.url);
					}}
					className={`sidebar-button-${i.key}`}
					showNotification={showNotificationIcon(i.key)}
				>
					{i.displayText}
				</SidebarItem>
			);
		} else if (source === Source.primary) {
			return (
				<div key={index} className="group relative">
					<Link
						key={index}
						href={i.url}
						className={cc([
							'block rounded-md px-3 py-2 text-base font-medium',
							{
								'bg-skin-navbar-mobile-menu-link text-skin-navbar-mobile-menu-active':
									active === i.key,
								'text-skin-navbar-mobile-menu-base hover:bg-skin-navbar-mobile-menu-link hover:bg-opacity-80 hover:text-white':
									active !== i.key,
							},
						])}
						aria-current="page"
						onClick={() => {
							track(TrackEvent[i.clickEventKey], {
								data: {
									location: 'mobileMenu',
									previousURL: router.pathname,
									...defaultTrackingMetadata.data,
								},
							} as MenuTrackingMetadata);
							onLinkClick();
						}}
					>
						{i.displayText}
					</Link>
					{showNotificationIcon(i.key) && (
						<span
							className={cc([
								'chat-notification-icon absolute right-4 top-4 z-20 h-2 w-2 rounded-full group-hover:bg-skin-badge-notification-mobile-menu-active',
								{
									'bg-skin-badge-notification-mobile-menu-active':
										active === i.key,
									'bg-skin-badge-notification-mobile-menu-base':
										active !== i.key,
								},
							])}
						></span>
					)}
				</div>
			);
		}
	};

	const NavigationLinks = renderFeatureFlaggedNavigationLinks(
		navigationLinks,
		isEnabled,
		showNotificationDefinitions,
	)
		.filter((i) => {
			const requestCreatorLinkValidation =
				i.key === 'requests' && isRequestCreator;
			const challengeCreatorLinkValidation =
				i.key === 'challenges' && isChallengeCreator;
			const challengeResponderLinkValidation =
				i.key === 'challenge-submissions' && isChallengeResponder;
			return (
				(i.key !== 'requests' &&
					i.key !== 'challenges' &&
					i.key !== 'challenge-submissions') || // allow all links except for requests, challenges, challenge submission (non-dynamic links)
				// filter out options if permissions [Vertical] are not assigned
				requestCreatorLinkValidation ||
				challengeCreatorLinkValidation ||
				challengeResponderLinkValidation
			);
		})
		.map(renderNavigationLinks);
	return <>{NavigationLinks}</>;
};
