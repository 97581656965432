import * as React from 'react';
import * as dnbDefinitions from './definitions/dnb-definitions.json';
import * as vintroDefinitions from './definitions/vintro-definitions.json';
import * as opennationDefinitions from './definitions/opennation-definitions.json';
import {
	AppSiteAwareTextKeys,
	DynamicValueKeys,
} from '@/components/app-site-aware-text/definitions/definition-keys';

interface AppSiteAwareText {
	textKey: AppSiteAwareTextKeys;
	dynamicValues?: [{ key: DynamicValueKeys; value: string | number }];
}

export const AppSiteAwareText: React.FC<AppSiteAwareText> = (props) => {
	const { textKey, dynamicValues } = props;

	const generateKeys = (definitions, dynamicValues?) => {
		let value = definitions;
		if (dynamicValues) {
			dynamicValues.map((dv) => {
				value = value.replace(dv.key, dv.value);
			});
		}

		return value;
	};

	switch (process.env.NEXT_PUBLIC_APP_SITE) {
		case 'dnb':
			return <>{generateKeys(dnbDefinitions[textKey], dynamicValues)}</>;
			break;
		case 'opennation':
			return <>{generateKeys(opennationDefinitions[textKey], dynamicValues)}</>;
			break;
		case 'vintro':
		default:
			return <>{generateKeys(vintroDefinitions[textKey], dynamicValues)}</>;
	}
};
