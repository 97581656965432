import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';
import { XIcon } from '@heroicons/react/outline';
import {
	TextHeading,
	HeadingSize,
	TextParagraph,
	TextParagraphSize,
} from '../typography/typography';
import { ReactiveVar } from '@apollo/client';
import { formatDate } from '../../utils/formatters';
import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';

enum notificationState {
	add = 'add',
	vanish = 'vanish',
	remove = 'remove',
}

interface LocalNotificationMessageProps extends PropsWithChildren {
	date: string;
	className?: string;
	footerUrl?: string;
	footerLabel?: string;
	anchorProps?: React.HtmlHTMLAttributes<HTMLHyperlinkElementUtils>;
	onClose: React.MouseEventHandler<HTMLButtonElement>;
}

interface NotificationsProps {
	className?: string;
	messages?: { message: string; id: number }[];
	displayTime?: number;
	notificationObj?: ReactiveVar<{
		messages: { id: number; message: string; displayTime: number }[];
		displayTime: number;
	}>;
}

interface NotificationMessageProps {
	title?: string;
	message: { message: string; id: number };
	className?: string;
	displayTime?: number;
	notificationObj?: ReactiveVar<{
		messages: { id: number; message: string; displayTime: number }[];
		displayTime: number;
	}>;
}

interface LocalNotificationsProps {
	className?: string;
	changeCounter?: (counterValue: number) => void;
	messages?: {
		icon?: string;
		isSolidIcon?: boolean;
		date: string;
		message: string;
		linkURL: string;
		linkLabel: string;
		onCloseMessage?: () => Promise<unknown>;
	}[];
}

export const addNotification = (notificationObj, textMessage) => {
	const randomId = new Date().valueOf();

	notificationObj({
		...notificationObj(),
		messages: [
			...(notificationObj().messages ?? []),
			{ message: textMessage, id: randomId },
		],
	});
};

export const NotificationMessage: React.FC<NotificationMessageProps> = (
	props,
) => {
	const {
		title,
		message,
		notificationObj,
		displayTime = 4000,
		className,
	} = props;

	const [isVisible, setIsVisible] = React.useState(notificationState.add);

	React.useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(notificationState.vanish);
			removeMessagesTimed();
		}, displayTime);
		return () => {
			clearTimeout(timer);
		};
	}, [message]);

	const onClose = () => {
		removeMessages();
	};

	const removeMessagesTimed = () => {
		setTimeout(() => {
			removeMessages();
		}, 500);
	};

	const removeMessages = () => {
		setIsVisible(notificationState.remove);
		if (notificationObj().messages) {
			const newMessages = notificationObj().messages.filter((item) => {
				return item.id !== message.id;
			});
			notificationObj({ ...notificationObj(), messages: newMessages });
		}
	};

	return (
		<>
			{isVisible !== notificationState.remove && (
				<div
					className={cc([
						'notification-message',
						'relative z-50 w-80 flex-col rounded-skin-card-notification bg-skin-card-notification-base px-4 pt-2 text-skin-card-notification-body shadow-skin-card-notification transition duration-200 ease-in',
						className,
						{
							block: isVisible === notificationState.add,
							'translate-x-10 opacity-0':
								isVisible === notificationState.vanish,
						},
					])}
				>
					<div
						className={cc([
							'absolute right-4 top-4 text-skin-card-notification-icon',
						])}
					>
						<button onClick={onClose} className="bg-transparent">
							<XIcon className={cc(['h-6 w-6 hover:text-white'])} />
						</button>
					</div>
					<div className={cc(['py-4'])}>
						{title && (
							<TextHeading headingSize={HeadingSize.h7}>{title}</TextHeading>
						)}
						<TextParagraph
							textParagraphSize={TextParagraphSize.p5}
							className="mt-6"
						>
							{message.message}
						</TextParagraph>
					</div>
				</div>
			)}
		</>
	);
};

export const LocalNotificationMessage: React.FC<LocalNotificationMessageProps> = (
	props,
) => {
	const { children, date, footerLabel, footerUrl, className, onClose } = props;

	const clickButton = (e) => {
		onClose(e);
	};

	return (
		<div
			className={cc([
				'relative right-0 z-0 w-80 flex-col rounded-skin-card-notification bg-skin-card-notification-base px-4 pt-2 text-skin-card-notification-body shadow-skin-card-notification transition duration-200 ease-in',
				className,
			])}
		>
			<div
				className={cc([
					'flex justify-between pb-4 text-tiny text-skin-card-notification-icon',
				])}
			>
				<p className={cc(['text-skin-card-notification-body'])}>
					{formatDate(date)}
				</p>
				<button onClick={clickButton} className={cc(['bg-transparent'])}>
					<XIcon className={cc(['h-6 w-6'])} />
				</button>
			</div>
			{children}
			{footerUrl && (
				<div
					className={cc([
						'mt-4 flex justify-end border-t-2 border-neutral-600',
					])}
				>
					<a
						className="py-4 text-skin-link-base hover:text-opacity-90"
						href={footerUrl}
						rel="noopener noreferrer"
					>
						{footerLabel}
					</a>
				</div>
			)}
		</div>
	);
};

export const Notifications: React.FC<NotificationsProps> = (props) => {
	const { messages, className, notificationObj, displayTime = 4000 } = props;

	return (
		<div
			className={cc([
				'absolute right-4 top-4 flex flex-col justify-items-start gap-2 overflow-y-auto overflow-x-hidden',
				className,
			])}
		>
			{messages &&
				messages.map((item) => (
					<NotificationMessage
						key={item.id}
						displayTime={displayTime}
						message={item}
						notificationObj={notificationObj}
						className={className}
					/>
				))}
		</div>
	);
};

export const LocalNotifications: React.FC<LocalNotificationsProps> = (
	props,
) => {
	const { messages, className, changeCounter } = props;

	const [notificationMessages, setNotificationMessages] = React.useState(
		messages,
	);

	React.useEffect(() => {
		changeCounter(notificationMessages.length);
	}, [notificationMessages]);

	const onCloseMessage = (index, item) => {
		setNotificationMessages(notificationMessages.filter((_, j) => index !== j));
		if (item.onCloseMessage) {
			item
				.onCloseMessage()
				.then(() => null)
				.catch(() => null);
		}
	};

	return (
		<div
			className={cc([
				'absolute -right-6 z-10 mt-3 flex flex-col overflow-auto rounded-b-md bg-skin-navbar-base p-4 shadow-skin-card-notification',
				className,
			])}
			style={{ maxHeight: 'calc(100vh - 192px)' }}
		>
			{notificationMessages &&
				notificationMessages.map((item, i) => {
					const icon = item.icon ? item.icon : 'AnnotationIcon';
					const HeroIcon = item.isSolidIcon
						? SolidIcons[icon]
						: OutlineIcons[icon];
					return (
						<LocalNotificationMessage
							key={i}
							date={item.date}
							footerLabel={item.linkLabel}
							footerUrl={item.linkURL}
							onClose={() => onCloseMessage(i, item)}
							className={cc(['mb-2'])}
						>
							<div className={cc(['flex'])}>
								<HeroIcon className={cc(['mr-2 h-6'])} />
								<p>{item.message}</p>
							</div>
						</LocalNotificationMessage>
					);
				})}
		</div>
	);
};
