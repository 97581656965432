import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';

export enum HeadingSize {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	h5 = 'h5',
	h6 = 'h6',
	h7 = 'h7',
	h8 = 'h8',
	h9 = 'h9',
	h10 = 'h10',
}

export enum TextParagraphSize {
	p1 = 'p1',
	p2 = 'p2',
	p3 = 'p3',
	p4 = 'p4',
	p5 = 'p5',
	p6 = 'p6',
	p7 = 'p7',
	p8 = 'p8',
}

export enum TextDisplaySize {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
}

export enum TextLabelSize {
	t1 = 't1',
	t2 = 't2',
	t3 = 't3',
}

interface TextHeadingProps extends PropsWithChildren {
	headingSize?: HeadingSize;
	className?: string;
}

interface TextParagraphProps extends PropsWithChildren {
	textParagraphSize?: TextParagraphSize;
	className?: string;
}

interface TextDisplayProps extends PropsWithChildren {
	textDisplaySize?: TextDisplaySize;
	className?: string;
}

interface TextLabelProps extends PropsWithChildren {
	textLabelTag?: keyof JSX.IntrinsicElements;
	textLabelSize?: TextLabelSize;
	className?: string;
}

export const TextHeading: React.FC<TextHeadingProps> = (props) => {
	const { children, headingSize = HeadingSize.h1, className } = props;

	let value;
	let textSize;

	switch (headingSize) {
		case HeadingSize.h1:
			value = headingSize;
			textSize = 'text-6xl';
			break;
		case HeadingSize.h2:
			value = headingSize;
			textSize = 'text-5xl';
			break;
		case HeadingSize.h3:
			value = headingSize;
			textSize = 'text-4xl';
			break;
		case HeadingSize.h4:
			value = headingSize;
			textSize = 'text-3xl';
			break;
		case HeadingSize.h5:
			value = headingSize;
			textSize = 'text-2xl';
			break;
		case HeadingSize.h6:
			value = `div`;
			textSize = 'text-xl';
			break;
		case HeadingSize.h7:
			value = `div`;
			textSize = 'text-lg';
			break;
		case HeadingSize.h8:
			value = `div`;
			textSize = 'text-base';
			break;
		case HeadingSize.h9:
			value = `div`;
			textSize = 'text-tiny';
			break;
		case HeadingSize.h10:
			value = `div`;
			textSize = 'text-sm';
			break;
	}

	const HeadingTag = value;

	return (
		<HeadingTag
			className={cc(['prose-paragraph break-words', textSize, className])}
		>
			{children}
		</HeadingTag>
	);
};

export const TextParagraph: React.FC<TextParagraphProps> = (props) => {
	const {
		textParagraphSize = TextParagraphSize.p1,
		className,
		children,
	} = props;

	let textSize;

	switch (textParagraphSize) {
		case TextParagraphSize.p1:
			textSize = 'text-3xl';
			break;
		case TextParagraphSize.p2:
			textSize = 'text-2xl';
			break;
		case TextParagraphSize.p3:
			textSize = 'text-xl';
			break;
		case TextParagraphSize.p4:
			textSize = 'text-lg';
			break;
		case TextParagraphSize.p5:
			textSize = 'text-base';
			break;
		case TextParagraphSize.p6:
			textSize = 'text-tiny';
			break;
		case TextParagraphSize.p7:
			textSize = 'text-sm';
			break;
		case TextParagraphSize.p8:
			textSize = 'text-xs';
			break;
	}
	return (
		<p className={cc(['prose-paragraph break-words', textSize, className])}>
			{children}
		</p>
	);
};

export const TextDisplay: React.FC<TextDisplayProps> = (props) => {
	const { children, textDisplaySize = TextDisplaySize.h1, className } = props;

	const TextDisplayTag = textDisplaySize;

	return (
		<div className={cc(['prose-display break-words', className])}>
			<TextDisplayTag>{children}</TextDisplayTag>
		</div>
	);
};

export const TextLabel: React.FC<TextLabelProps> = (props) => {
	const {
		children,
		textLabelTag = 'div',
		textLabelSize = TextLabelSize.t3,
		className,
	} = props;

	const TextLabelTag = textLabelTag as keyof JSX.IntrinsicElements;

	return (
		<TextLabelTag
			className={cc([
				'font-headline uppercase tracking-widest',
				{
					'text-base': textLabelSize === TextLabelSize.t1,
					'text-tiny': textLabelSize === TextLabelSize.t2,
					'text-sm': textLabelSize === TextLabelSize.t3,
				},
				className,
			])}
		>
			{children}
		</TextLabelTag>
	);
};
