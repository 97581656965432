export const promptConfirmRouteChange = (router) => {
	const handleRouteChange = (url) => {
		if (url.includes('disableConfirmAlert=true')) {
			return true;
		}

		//check these urls
		const urls = ['/dashboard/appointments/join'];

		urls.forEach((path) => {
			if (!url.includes(path) && window.location.pathname.includes(path)) {
				const result = confirm('Are you sure you want to leave?');

				if (result === true) {
					return true;
				} else {
					router.events.emit('routeChangeError');
					throw `routeChange aborted`;
				}
			}
		});
	};

	router.events.on('beforeHistoryChange', handleRouteChange);

	// If the component is unmounted, unsubscribe
	// from the event with the `off` method:
	return () => {
		router.events.off('beforeHistoryChange', handleRouteChange);
	};
};
