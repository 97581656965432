import vintroContactInfoJSON from '../contact-info/vintro.json';
import dnbContactInfoJSON from '../contact-info/dnb.json';
import opennationContactInfoJSON from '../contact-info/opennation.json';

interface ContactInfo {
	phone: string;
	email: string;
	address: string;
}

export const getContactInfo = (): ContactInfo => {
	// The production build needs the env var to be cast to string explicitly
	const appSite: string = (process.env.NEXT_PUBLIC_APP_SITE || '').toString();

	if (appSite === 'dnb') {
		return dnbContactInfoJSON;
	} else if (appSite === 'opennation') {
		return opennationContactInfoJSON;
	} else {
		return vintroContactInfoJSON;
	}
};
