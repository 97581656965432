import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';
import { AvatarItemSize, DataAvatarsItem } from '../../data-values-avatars';
import MenuButton from '../../menu-button';
import { Search, SearchInputSize } from '../../search/search';
import { BackgroundColor, RingColor } from '../../global/enum';
import TextButton, { TextButtonType } from '../../textbutton';
import IconButton from '../../icon-button';
import CircleButton, { CircleButtonType } from '../../circle-button';
import { BellIcon, ShoppingCartIcon } from '@heroicons/react/outline';
import { LocalNotifications } from '../../notifications';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import Button, { ButtonTheme, ButtonType } from '../../button';

interface NavbarProps extends PropsWithChildren {
	id?: string;
}

interface NavbarSectionProps extends PropsWithChildren {
	className?: string;
}

interface NavbarSectionItemProps extends PropsWithChildren {
	className?: string;
}

interface NavbarNotificationsProps {
	className?: string;
	messages?: {
		icon?: string;
		isSolidIcon?: boolean;
		date: string;
		message: string;
		linkURL: string;
		linkLabel: string;
	}[];
	onClick?: () => void;
}

interface NavbarProfileNameProps extends PropsWithChildren {
	className?: string;
}

interface NavbarProfileProps {
	className?: string;
	src: string;
	alt: string;
	isOpen: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface NavBarSearchProps {
	className?: string;
	isOpen: boolean;
	logo?: boolean;
	searchBordered?: boolean;
	searchSize?: SearchInputSize;
	hasSearch?: boolean;
	hasChat?: boolean;
	onSearchChange?: () => null;
	onClick: () => void;
	onChatClick?: () => void;
	searchInputProps?: { disabled?: boolean; id: string; placeholder: string };
}

interface NavBarProfileMenuProps {
	className?: string;
	classNameBlock?: string;
	userName: string;
	profileImage?: string;
	initials?: string;
	classNameText?: string;
	onClick: () => void;
}

interface NavbarCartButtonProps {
	className?: string;
	cartItems?: string;
	onClick: () => void;
}

export const Navbar: React.FC<NavbarProps> = (props) => {
	const { children, id } = props;

	return (
		<div>
			<nav
				id={id}
				className={cc(
					'w-full border-b-skin-navbar border-skin-navbar-base bg-skin-navbar-base',
				)}
			>
				<div className="relative flex h-16 w-full items-center justify-between pl-6 pr-6">
					{children}
				</div>
			</nav>
		</div>
	);
};

export const NavbarSection: React.FC<NavbarSectionProps> = (props) => {
	const { children, className } = props;

	return <div className={className}>{children}</div>;
};

export const NavbarSectionItem: React.FC<NavbarSectionItemProps> = (props) => {
	const { children, className } = props;

	return (
		<div
			className={cc([
				'flex flex-1 items-center justify-start sm:justify-start',
				className,
			])}
		>
			{children}
		</div>
	);
};

// @todo is this used - can we remove
export const NavbarItemSeparator = (props: React.ComponentProps<'div'>) => {
	const { className } = props;

	return (
		<span
			className={cc(['ml-6 mr-6 h-8 w-px bg-neutral-600', className])}
		></span>
	);
};

export const NavbarCartButton: React.FC<NavbarCartButtonProps> = (props) => {
	const { className, cartItems = '0', onClick } = props;

	return (
		<>
			<div className={cc(['hidden lg:flex', className])}>
				<Button
					backgroundColor={BackgroundColor.neutralDarkest}
					onClick={onClick}
					theme={ButtonTheme.dark}
					type={ButtonType.primary}
				>
					Cart ({cartItems})
				</Button>
			</div>
			<div className={cc(['sm:flex lg:hidden'])}>
				<CircleButton
					className="h-10 w-10"
					onClick={onClick}
					type={CircleButtonType.counter}
				>
					<ShoppingCartIcon className="h-6 w-6 stroke-current text-white"></ShoppingCartIcon>
					<span
						className={
							'group-selected:bg-white group-selected:text-black absolute left-5 bottom-1 flex h-4 w-3.5 items-center justify-center rounded-full bg-skin-button-base font-body text-xs text-white group-active:bg-white group-active:text-black'
						}
					>
						{cartItems}
					</span>
				</CircleButton>
			</div>
		</>
	);
};

export const NavBarProfileMenu: React.FC<NavBarProfileMenuProps> = (props) => {
	const {
		className,
		classNameBlock,
		classNameText,
		userName,
		initials,
		profileImage,
		onClick,
	} = props;

	return (
		<>
			<div className={cc(['relative mr-3 ml-3', className])}>
				<div>
					{profileImage && (
						<button
							type="button"
							className={cc([
								'flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800',
							])}
							id="user-menu-button"
							aria-expanded="false"
							aria-haspopup="true"
						>
							<span className={cc(['sr-only'])}>Open user menu</span>
							<img
								className={cc(['h-8 w-8 rounded-full'])}
								src={profileImage}
								alt=""
							/>
						</button>
					)}
					{initials && (
						<CircleButton
							onClick={onClick}
							className="h-10 w-10"
							type={CircleButtonType.text}
						>
							<span
								className={
									'group-selected:bg-white group-selected:text-black bottom-1 flex items-center justify-center rounded-full bg-skin-button-circle font-body text-base text-skin-button-circle group-active:bg-black group-active:text-white'
								}
							>
								{initials}
							</span>
						</CircleButton>
					)}
				</div>
			</div>
			<div className={cc(['ml-3 hidden sm:flex', classNameBlock])}>
				<TextButton
					onClick={onClick}
					type={TextButtonType.white}
					textSize={cc([classNameText])}
				>
					{userName}
				</TextButton>
			</div>
		</>
	);
};

export const NavbarSearch: React.FC<NavBarSearchProps> = (props) => {
	const {
		onSearchChange,
		hasSearch = false,
		searchBordered,
		searchSize,
		searchInputProps,
		logo = true,
		hasChat = false,
		onClick,
		onChatClick,
	} = props;

	return (
		<div className="flex items-center divide-x divide-white/20">
			<div className="flex items-center">
				<MenuButton onClick={onClick} />
				{logo && (
					<div className={cc(['flex shrink-0 items-center px-6'])}>
						<svg
							width="66"
							height="18"
							viewBox="0 0 66 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 1.03369H3.73607L7.68104 13.1999L11.722 1.03369H15.3621L9.79398 17.4762H5.5897L0 1.03369Z"
								fill="white"
							/>
							<path
								d="M19.5427 0.904048C19.7198 1.07589 19.8592 1.28265 19.9522 1.51121C20.0452 1.73978 20.0897 1.98517 20.0829 2.23184C20.0889 2.47947 20.044 2.72568 19.9511 2.95529C19.8582 3.1849 19.7192 3.39303 19.5427 3.56684C19.3652 3.74588 19.1527 3.88646 18.9185 3.97982C18.6842 4.07318 18.4333 4.11731 18.1813 4.10948C17.9272 4.11674 17.6743 4.0724 17.4378 3.97913C17.2013 3.88586 16.9862 3.7456 16.8055 3.56684C16.6272 3.39414 16.4867 3.18632 16.3929 2.95653C16.2991 2.72673 16.254 2.47997 16.2604 2.23184C16.2537 1.98431 16.2991 1.73813 16.3939 1.50935C16.4886 1.28057 16.6305 1.07432 16.8103 0.904048C16.9935 0.729764 17.2094 0.593385 17.4455 0.502737C17.6816 0.412088 17.9333 0.368954 18.1861 0.375812C18.4361 0.368436 18.685 0.411398 18.918 0.502144C19.1511 0.592889 19.3635 0.729566 19.5427 0.904048ZM16.5125 5.40126H19.826V17.4763H16.5125V5.40126Z"
								fill="white"
							/>
							<path
								d="M32.7725 6.57779C33.6801 7.45339 34.1339 8.90123 34.1339 10.9213V17.4763H30.8228V11.111C30.8228 9.03009 30.008 7.98962 28.3785 7.98962C28.002 7.96788 27.6256 8.03426 27.2792 8.18348C26.9328 8.3327 26.626 8.56065 26.3832 8.84921C25.9126 9.43027 25.6781 10.245 25.6797 11.2935V17.4715H22.3662V5.40127H25.2475L25.5548 6.90674C25.9688 6.39953 26.4894 5.98966 27.0795 5.7062C27.7734 5.39591 28.5288 5.2473 29.2885 5.27161C30.7035 5.26521 31.8649 5.7006 32.7725 6.57779Z"
								fill="white"
							/>
							<path
								d="M37.2399 8.19847H35.2422V5.40122H37.2399V2.02051H40.551V5.40122H43.2978V8.19847H40.551V13.4808C40.5204 13.8089 40.6166 14.1363 40.8199 14.3956C40.9442 14.5009 41.0883 14.5802 41.2438 14.6289C41.3993 14.6776 41.5629 14.6946 41.7251 14.679H43.4395V17.4738H40.6926C38.3908 17.4738 37.2399 16.3229 37.2399 14.0211V8.19847Z"
								fill="white"
							/>
							<path
								d="M52.6574 5.40148V8.41003H51.3416C50.402 8.41003 49.7129 8.69176 49.2743 9.25521C48.8357 9.81866 48.6172 10.5854 48.6188 11.5554V17.4765H45.3125V5.40148H48.3186L48.626 7.21189C48.9472 6.65308 49.408 6.18728 49.9634 5.86009C50.6242 5.52553 51.3604 5.36755 52.1003 5.40148H52.6574Z"
								fill="white"
							/>
							<path
								d="M56.2855 16.852C55.3453 16.3671 54.5693 15.6153 54.0549 14.691C53.5173 13.6965 53.246 12.5799 53.2673 11.4496C53.2455 10.3192 53.5168 9.20244 54.0549 8.2081C54.5678 7.27958 55.3439 6.52353 56.2855 6.03513C57.3297 5.53249 58.4737 5.27148 59.6326 5.27148C60.7915 5.27148 61.9355 5.53249 62.9797 6.03513C63.9225 6.52268 64.6996 7.27888 65.2127 8.2081C65.7499 9.20266 66.0204 10.3194 65.9978 11.4496C66.0231 12.5831 65.7525 13.7036 65.2127 14.7006C64.697 15.6248 63.9202 16.3764 62.9797 16.8616C61.9355 17.3642 60.7915 17.6252 59.6326 17.6252C58.4737 17.6252 57.3297 17.3642 56.2855 16.8616V16.852ZM61.8296 13.9515C62.3706 13.3112 62.6411 12.474 62.6411 11.4399C62.6411 10.4059 62.3706 9.56791 61.8296 8.92603C61.5613 8.60846 61.2237 8.35672 60.8428 8.19021C60.4619 8.02369 60.0479 7.94685 59.6326 7.9656C59.223 7.95025 58.8153 8.02881 58.4408 8.19528C58.0662 8.36175 57.7347 8.61171 57.4716 8.92603C56.9322 9.56631 56.6617 10.4043 56.6601 11.4399C56.6585 12.4756 56.929 13.3128 57.4716 13.9515C57.7347 14.2658 58.0662 14.5157 58.4408 14.6822C58.8153 14.8487 59.223 14.9273 59.6326 14.9119C60.0479 14.9307 60.4619 14.8538 60.8428 14.6873C61.2237 14.5208 61.5613 14.269 61.8296 13.9515Z"
								fill="white"
							/>
						</svg>
					</div>
				)}
			</div>
			<div className="flex items-center divide-x divide-white/20">
				{hasSearch && (
					<div className={cc(['hidden sm:mx-6 md:flex'])}>
						<div className={cc(['flex space-x-4'])}>
							<Search
								bordered={searchBordered}
								size={searchSize}
								onSearchChange={onSearchChange}
								inputProps={searchInputProps}
							/>
						</div>
					</div>
				)}
				{hasChat && (
					<div className={cc(['hidden pl-4 md:flex'])}>
						<IconButton icon="chat" onClick={onChatClick}></IconButton>
					</div>
				)}
			</div>
		</div>
	);
};

export const NavbarProfile: React.FC<NavbarProfileProps> = (props) => {
	const { className, src, alt, isOpen, onClick } = props;

	return (
		<button
			className={cc([className])}
			onClick={onClick}
			aria-expanded={isOpen}
			aria-haspopup="true"
		>
			<DataAvatarsItem
				ringColor={RingColor.avatar}
				size={AvatarItemSize.sm}
				src={src}
				alt={alt}
				className={cc(['max-w-max bg-white'])}
			/>
			<span className={cc(['sr-only'])}>Open user menu</span>
		</button>
	);
};

export const NavbarProfileName: React.FC<NavbarProfileNameProps> = (props) => {
	const { className, children } = props;

	return (
		<div className={cc(['ml-3 hidden sm:flex', className])}>{children}</div>
	);
};

export const NavbarNotifications: React.FC<NavbarNotificationsProps> = (
	props,
) => {
	const { className, messages = [], onClick } = props;
	const [viewNotifications, setViewNotifications] = React.useState(false);
	const [counter, setCounter] = React.useState(0);

	const containerRef = React.useRef(null);

	useClickOutside(containerRef, viewNotifications, () =>
		setViewNotifications(false),
	);

	const updateCounter = (counterValue) => {
		setCounter(counterValue);
	};

	React.useEffect(() => {
		if (messages.length === 0) {
			setViewNotifications(false);
		}
		updateCounter(messages.length);
	}, [messages]);

	return (
		<div className={cc(['relative', className])} ref={containerRef}>
			<div>
				<CircleButton
					onClick={() => {
						onClick();
						setViewNotifications(!viewNotifications);
					}}
					type={CircleButtonType.notifications}
					disabled={messages.length < 1}
					selected={viewNotifications}
					className="h-10 w-10"
				>
					<BellIcon
						className={cc([
							'h-6',
							{
								'text-skin-base-icon': !viewNotifications,
								'text-skin-base-icon-active': viewNotifications,
							},
						])}
					/>
					<span
						className={
							'group-selected:bg-white group-selected:text-black absolute left-5 top-1 bottom-1 flex h-4 w-3.5 items-center justify-center rounded-full bg-skin-button-base font-body text-xs text-white group-active:bg-white group-active:text-black'
						}
					>
						{counter}
					</span>
				</CircleButton>
			</div>

			{viewNotifications && messages.length > 0 && (
				<LocalNotifications messages={messages} changeCounter={updateCounter} />
			)}
		</div>
	);
};
