import { useRouter } from 'next/router';
import * as React from 'react';
import { useAnalytics } from 'use-analytics';

export const AnalyticsTrackPageload = () => {
	const router = useRouter();
	const { page } = useAnalytics();
	React.useEffect(() => {
		if (router.isReady) {
			page({
				site: process.env.NEXT_PUBLIC_APP_SITE,
				locale: process.env.NEXT_PUBLIC_LOCALE,
			});
		}
	}, [router, page]);

	return <></>;
};
