const plugin = require('tailwindcss/plugin');
const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

// Fix deprecated Tailwind Colors - warning errors
delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

// import theme options
let currentTheme;
if (process.env.NEXT_PUBLIC_APP_SITE === 'dnb') {
	currentTheme = require('../app/theme/dnb.json');
} else if (process.env.NEXT_PUBLIC_APP_SITE === 'opennation') {
	currentTheme = require('../app/theme/opennation.json');
} else {
	currentTheme = require('../app/theme/default.json');
}

const baseTheme = {
	screens: {
		sm: '768px',
		md: '1024px',
		lg: '1440px',
		xl: '1920px',
	},
	colors: {
		primary: {
			DEFAULT: '#EF6348',
			'brand-1': '#253C74',
			'brand-2': '#EF6348',
			'default-lighter': '#F1735A',
			'default-darker': '#D75941',
		},
		secondary: {
			DEFAULT: '#000',
			'brand-1': '#F4F5F1',
			'brand-2': '#E5E9DD',
			'brand-3': '#0A9669',
		},
		neutral: {
			100: '#F5F5F5',
			200: '#EBEBEB',
			300: '#CCCCCC',
			400: '#999999',
			500: '#767676',
			600: '#414141',
			700: '#333333',
			800: '#202020',
			900: '#141414',
		},
		success: {
			DEFAULT: '#52C278',
		},
		danger: {
			DEFAULT: '#CD455D',
		},
		warning: {
			...colors.amber,
		},
		info: {
			...colors.sky,
		},
		light: {
			...colors.white,
		},
		dark: {
			...colors.stone,
		},
		transparent: 'transparent',
		current: 'currentColor',
	},
};

module.exports = {
	content: [
		'../components/**/*.{js,ts,jsx,tsx,scss}',
		'./components/**/*.{js,ts,jsx,tsx,scss}',
		'./pages/**/*.{js,ts,jsx,tsx,scss}',
	],
	theme: {
		extend: {
			typography: (theme) => ({
				heading: {
					css: {
						'font-family': currentTheme.tailwindConfig.fonts.headline,
					},
				},
				paragraph: {
					css: {
						'font-family': currentTheme.tailwindConfig.fonts.body,
					},
				},
				display: {
					css: {
						'font-family': currentTheme.tailwindConfig.fonts.secondary,
						h1: {
							'font-size': '48px',
							'line-height': '52px',
						},
						h2: {
							'font-size': '40px',
							'line-height': '44px',
						},
						h3: {
							'font-size': '32px',
							'line-height': '36px',
						},
					},
				},
			}),
			...currentTheme.tailwindConfig,
		},
		screens: { ...baseTheme.screens },
		colors: {
			...colors,
			...baseTheme.colors,
		},
		boxShadow: {
			DEFAULT: '0 4px 16px rgba(0, 0, 0, 0.08)',
			md: '0 4px 16px rgba(0, 0, 0, 0.16)',
			lg: '0 4px 16px rgba(0, 0, 0, 0.24)',
			xl: '0 4px 16px rgba(0, 0, 0, 0.32)',
			none: 'none',
		},
		fontFamily: {
			...defaultTheme.fontFamily,
			...currentTheme.tailwindConfig.fonts,
		},
		fontSize: {
			xs: ['.625rem', { lineHeight: '1.125rem' }],
			sm: ['.75rem', { lineHeight: '1.25rem' }],
			tiny: ['.875rem', { lineHeight: '1.375rem' }],
			base: ['1rem', { lineHeight: '1.5rem' }],
			lg: ['1.125rem', { lineHeight: '1.625rem' }],
			xl: ['1.25rem', { lineHeight: '1.75rem' }],
			'2xl': ['1.5rem', { lineHeight: '2rem' }],
			'3xl': ['2rem', { lineHeight: '2.5rem' }],
			'4xl': ['2.5rem', { lineHeight: '3rem' }],
			'5xl': ['3.5rem', { lineHeight: '4rem' }],
			'6xl': ['4.5rem', { lineHeight: '5rem' }],
		},
	},
	plugins: [
		require('@tailwindcss/forms'),
		require('@tailwindcss/typography'),
		require('@tailwindcss/aspect-ratio'),
		require('@tailwindcss/line-clamp'),
		plugin(function ({ addUtilities }) {
			addUtilities({
				'.scrollbar-none': {
					'-ms-overflow-style': 'none',
					'scrollbar-width': 'none',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				},
			});
		}),
	],
};
