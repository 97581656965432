import * as React from 'react';
import { EnvBanner } from '@/components/debug/env-banner';
import { NavigationLogo } from '@theme/components/navigation/components/navigation-logo';

interface LayoutMarketingSprinklerLandingProps {
	children: React.ReactNode;
}
export const LayoutMarketingSprinklerLanding: React.FC<LayoutMarketingSprinklerLandingProps> = (
	props,
) => {
	const { children } = props;
	return (
		<div className="layout-marketing-sprinkler-landing">
			<div
				id="nav-containers"
				className={'border-b border-neutral-400 bg-white px-8'}
			>
				<NavigationLogo />
				<EnvBanner />
			</div>
			<div className={'bg-white'}>{children}</div>
		</div>
	);
};
