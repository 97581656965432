import { useEffect } from 'react';

export const useClickOutside = (containerRef, isOpen, onClose) => {
	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (!containerRef?.current?.contains(e.target)) {
				if (!isOpen) return;
				onClose();
			}
		};

		window.addEventListener('click', handleOutsideClick);
		return () => window.removeEventListener('click', handleOutsideClick);
	}, [isOpen, containerRef]);

	useEffect(() => {
		const handleEscape = (e) => {
			if (!isOpen) return;

			if (e.key === 'Escape') {
				onClose();
			}
		};

		document.addEventListener('keyup', handleEscape);
		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen, containerRef]);
};
