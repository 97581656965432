import mixpanelPlugin from '@analytics/mixpanel';
import intercomPlugin from '@analytics/intercom';
import Analytics from 'analytics';

const plugins = () => {
	if (process.env.NODE_ENV === 'test') {
		return [];
	} else {
		return [
			intercomPlugin({
				appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			}),
			mixpanelPlugin({
				token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
				pageEvent: 'Page Load',
			}),
			{
				name: 'debugAnalytics',
				page: ({ payload }) => {
					if (process.env.NEXT_PUBLIC_ANALYTICS_DEBUG_ENABLED === 'true') {
						console.log('page view fired', payload);
					}
				},
				track: ({ payload }) => {
					if (process.env.NEXT_PUBLIC_ANALYTICS_DEBUG_ENABLED === 'true') {
						console.log('track view fired', { payload });
					}
				},
			},
		];
	}
};

const analytics = Analytics({
	app: 'Test Vintro',
	plugins: plugins(),
});

export default analytics;

export const defaultTrackingMetadata: DefaultTrackingMetadata = {
	data: {
		site: process.env.NEXT_PUBLIC_APP_SITE,
		locale: process.env.NEXT_PUBLIC_LOCALE,
	},
};

export enum TrackEvent {
	RequestCheckoutPlaceOrderClicked = 'RequestCheckoutPlaceOrderClicked',
	RequestCheckoutPrivacyPolicyLinkClicked = 'RequestCheckoutPrivacyPolicyLinkClicked',
	RequestCheckoutTermsOfServiceLinkClicked = 'RequestCheckoutTermsOfServiceLinkClicked',
	RequestCheckoutProfileHeadingLinkClicked = 'RequestCheckoutProfileHeadingLinkClicked',
	RequestCheckoutOpportunityHeadingLinkClicked = 'RequestCheckoutOpportunityHeadingLinkClicked',
	RequestCheckoutProfileDetailsLinkClicked = 'RequestCheckoutProfileDetailsLinkClicked',
	RequestCheckoutOpportunityDetailsLinkClicked = 'RequestCheckoutOpportunityDetailsLinkClicked',
	RequestCheckoutConfirmingOrderShown = 'RequestCheckoutConfirmingOrderShown',
	PostCheckoutScheduleMeetingClicked = 'PostCheckoutScheduleMeetingClicked',
	PostCheckoutSuggestOtherDatesClicked = 'PostCheckoutSuggestOtherDatesClicked',
	PostCheckoutUseSuggestedDatesClicked = 'PostCheckoutUseSuggestedDatesClicked',
	PostCheckoutSuggestOtherDatesAddDateClicked = 'PostCheckoutSuggestOtherDatesAddDateClicked',
	FeedCardRequestSaveClicked = 'FeedCardRequestSaveClicked',
	FeedCardRequestViewProfileClicked = 'FeedCardRequestViewProfileClicked',
	FeedCardRequestCallClicked = 'FeedCardRequestCallClicked',
	FeedRequestsPaginationPageClicked = 'FeedRequestsPaginationPageClicked',
	FeedRequestsPaginationPreviousPageClicked = 'FeedRequestsPaginationPreviousPageClicked',
	FeedRequestsPaginationNextPageClicked = 'FeedRequestsPaginationNextPageClicked',
	FeedRequestsTabClicked = 'FeedRequestsTabClicked',
	FeedChallengesTabClicked = 'FeedChallengesTabClicked',
	FeedCardChallengeSaveClicked = 'FeedCardChallengeSaveClicked',
	FeedCardChallengeViewProfileClicked = 'FeedCardChallengeViewProfileClicked',
	FeedCardRespondToChallengeClicked = 'FeedCardRespondToChallengeClicked',
	FeedChallengesPaginationPageClicked = 'FeedChallengesPaginationPageClicked',
	FeedChallengesPaginationPreviousPageClicked = 'FeedChallengesPaginationPreviousPageClicked',
	FeedChallengesPaginationNextPageClicked = 'FeedChallengesPaginationNextPageClicked',
	VerifyBannerVerifyButtonClicked = 'VerifyBannerVerifyButtonClicked',
	NavigationUserMenuOpened = 'NavigationUserMenuOpened',
	NavigationAddRequestButtonClicked = 'NavigationAddRequestButtonClicked',
	NavigationAddChallengeButtonClicked = 'NavigationAddChallengeButtonClicked',
	NavigationUserMenuAccountLinkClicked = 'NavigationUserMenuAccountLinkClicked',
	NavigationUserMenuUpdateCompanyProfileLinkClicked = 'NavigationUserMenuUpdateCompanyProfileLinkClicked',
	NavigationUserMenuOrdersLinkClicked = 'NavigationUserMenuOrdersLinkClicked',
	NavigationUserMenuSwitchOrganizationLinkClicked = 'NavigationUserMenuSwitchOrganizationLinkClicked',
	NavigationUserMenuLogoutClicked = 'NavigationUserMenuLogoutClicked',
	NavigationNotificationsClicked = 'NavigationNotificationsClicked',
	LandingPageHowDoesItWorkLinkClicked = 'LandingPageHowDoesItWorkLinkClicked',
	LandingPageSignupClicked = 'LandingPageSignupClicked',
	LandingPageLoginClicked = 'LandingPageLoginClicked',
	TeamTeamMembersTabClicked = 'TeamTeamMembersTabClicked',
	TeamInvitesTabClicked = 'TeamInvitesTabClicked',
	TeamMembersTabInviteTeamMemberClicked = 'TeamMembersTabInviteTeamMemberClicked',
	TeamInvitesTabInviteTeamMemberClicked = 'TeamInvitesTabInviteTeamMemberClicked',
	TeamInvitesTabResendInviteClicked = 'TeamInvitesTabResendInviteClicked',
	TeamInvitesTabRevokeInviteClicked = 'TeamInvitesTabRevokeInviteClicked',
	TeamTeamMembersEditClicked = 'TeamTeamMembersEditClicked',
	TeamTeamMembersDeleteClicked = 'TeamTeamMembersDeleteClicked',
	OpportunityDetailRequestCallClicked = 'OpportunityDetailRequestCallClicked',
	OpportunityDetailRespondToChallengeClicked = 'OpportunityDetailRespondToChallengeClicked',
	ProfileRequestsTabClicked = 'ProfileRequestsTabClicked',
	ProfileChallengesTabClicked = 'ProfileChallengesTabClicked',
	SavedLoadMoreClicked = 'SavedLoadMoreClicked',
	SavedToggledSavedClicked = 'SavedToggledSavedClicked',
	SavedCardRequestCallClicked = 'SavedCardRequestCallClicked',
	SavedCardRespondToChallengeClicked = 'SavedCardRespondToChallengeClicked',
	RequestDetailBackButtonClicked = 'RequestDetailBackButtonClicked',
	RequestDetailEditRequestLinkClicked = 'RequestDetailEditRequestLinkClicked',
	RequestDetailDuplicateRequestLinkClicked = 'RequestDetailDuplicateRequestLinkClicked',
	RequestDetailArchiveLinkClicked = 'RequestDetailArchiveLinkClicked',
	RequestListAddRequestClicked = 'RequestListAddRequestClicked',
	RequestListViewRequestClicked = 'RequestListViewRequestClicked',
	RequestListViewArchivedRequestsLinkClicked = 'RequestListViewArchivedRequestsLinkClicked',
	RequestListViewArchivedRequestClicked = 'RequestListViewArchivedRequestClicked',
	RequestListViewPaginationNextPageClicked = 'RequestListViewPaginationNextPageClicked',
	RequestListViewPaginationPreviousPageClicked = 'RequestListViewPaginationPreviousPageClicked',
	RequestListViewPaginationPageClicked = 'RequestListViewPaginationPageClicked',
	RequestListSortOrderChanged = 'RequestListSortOrderChanged',
	PostCallEngagementConnectOnPlatformClicked = 'PostCallEngagementConnectOnPlatformClicked',
	PostCallEngagementShareInfoClicked = 'PostCallEngagementShareInfoClicked',
	PostCallEngagementDenySubmissionClicked = 'PostCallEngagementDenySubmissionClicked',
	PostCallEngagementNoContactClicked = 'PostCallEngagementNoContactClicked',
	ChallengeSubmissionsViewChallengeClicked = 'ChallengeSubmissionsViewChallengeClicked',
	AddChallengeResponseBackButtonClicked = 'AddChallengeResponseBackButtonClicked',
	AddChallengeResponseDetailsShowMoreToggleClicked = 'AddChallengeResponseDetailsShowMoreToggleClicked',
	AddChallengeResponseSubmitClicked = 'AddChallengeResponseSubmitClicked',
	MenuOpportunitiesLinkClicked = 'MenuOpportunitiesLinkClicked',
	MenuAppointmentsLinkClicked = 'MenuAppointmentsLinkClicked',
	MenuRequestsLinkClicked = 'MenuRequestsLinkClicked',
	MenuChallengesLinkClicked = 'MenuChallengesLinkClicked',
	MenuChallengeSubmissionsLinkClicked = 'MenuChallengeSubmissionsLinkClicked',
	MenuChatLinkClicked = 'MenuChatLinkClicked',
	MenuTeamLinkClicked = 'MenuTeamLinkClicked',
	MenuSavedLinkClicked = 'MenuSavedLinkClicked',
	AppointmentSchedulePendingAppointmentTabClicked = 'AppointmentSchedulePendingAppointmentTabClicked',
	AppointmentWaitingOnOtherPartyAppointmentTabClicked = 'AppointmentWaitingOnOtherPartyAppointmentTabClicked',
	AppointmentUpcomingAppointmentsTabClicked = 'AppointmentUpcomingAppointmentsTabClicked',
	AppointmentCancelledAppointmentTabClicked = 'AppointmentCancelledAppointmentTabClicked',
	AppointmentScheduleProfileLinkClicked = 'AppointmentScheduleProfileLinkClicked',
	AppointmentScheduleOpportunityLinkClicked = 'AppointmentScheduleOpportunityLinkClicked',
	AppointmentScheduleDetailButtonClicked = 'AppointmentScheduleDetailButtonClicked',
	AppointmentScheduleCancelButtonClicked = 'AppointmentScheduleCancelButtonClicked',
	AppointmentScheduleRejectButtonClicked = 'AppointmentScheduleRejectButtonClicked',
	AppointmentWaitingProfileLinkClicked = 'AppointmentWaitingProfileLinkClicked',
	AppointmentWaitingOpportunityLinkClicked = 'AppointmentWaitingOpportunityLinkClicked',
	AppointmentWaitingRejectButtonClicked = 'AppointmentWaitingRejectButtonClicked',
	AppointmentWaitingCancelButtonClicked = 'AppointmentWaitingCancelButtonClicked',
	AppointmentUpcomingStartCallClicked = 'AppointmentUpcomingStartCallClicked',
	AppointmentUpcomingCancelClicked = 'AppointmentUpcomingCancelClicked',
	AppointmentUpcomingRejectClicked = 'AppointmentUpcomingRejectClicked',
	AppointmentUpcomingDetailClicked = 'AppointmentUpcomingDetailClicked',
	AppointmentUpcomingDetailStartCallClicked = 'AppointmentUpcomingDetailStartCallClicked',
	AppointmentUpcomingDetailAdditionalOptionsClicked = 'AppointmentUpcomingDetailAdditionalOptionsClicked',
	AppointmentUpcomingDetailSendRunningLateMessageClicked = 'AppointmentUpcomingDetailSendRunningLateMessageClicked',
	AppointmentUpcomingDetailRescheduleAppointmentClicked = 'AppointmentUpcomingDetailRescheduleAppointmentClicked',
	AppointmentUpcomingDetailProfileLinkClicked = 'AppointmentUpcomingDetailProfileLinkClicked',
	AppointmentCallEndCallClicked = 'AppointmentCallEndCallClicked',
	AppointmentCallLeaveCallClicked = 'AppointmentCallLeaveCallClicked',
	AppointmentCallStarted = 'AppointmentCallStarted',
	AppointmentCancelledProfileLinkClicked = 'AppointmentCancelledProfileLinkClicked',
	AppointmentCancelledOpportunityLinkClicked = 'AppointmentCancelledOpportunityLinkClicked',
	AppointmentCancelledReasonLinkClicked = 'AppointmentCancelledReasonLinkClicked',
	AppointmentScheduleDetailSuggestOtherDatesClicked = 'AppointmentScheduleDetailSuggestOtherDatesClicked',
	AppointmentScheduleDetailUseSuggestedDatesClicked = 'AppointmentScheduleDetailUseSuggestedDatesClicked',
	AppointmentScheduleDetailScheduleMeetingClicked = 'AppointmentScheduleDetailScheduleMeetingClicked',
	AppointmentScheduleDetailSuggestOtherDatesAddDateClicked = 'AppointmentScheduleDetailSuggestOtherDatesAddDateClicked',
	AppointmentConfirmDetailSuggestOtherDatesClicked = 'AppointmentConfirmDetailSuggestOtherDatesClicked',
	AppointmentConfirmDetailUseSuggestedDatesClicked = 'AppointmentConfirmDetailUseSuggestedDatesClicked',
	AppointmentConfirmDetailConfirmMeetingClicked = 'AppointmentConfirmDetailConfirmMeetingClicked',
	AppointmentConfirmDetailSuggestNewDatesClicked = 'AppointmentConfirmDetailSuggestNewDatesClicked',
	AppointmentConfirmDetailSuggestOtherDatesAddDateClicked = 'AppointmentConfirmDetailSuggestOtherDatesAddDateClicked',
	ChallengesResponseListBackButtonClicked = 'ChallengesResponseListBackButtonClicked',
	ChallengesResponseAcceptedResponseFilterClicked = 'ChallengesResponseAcceptedResponseFilterClicked',
	ChallengesResponseRejectedResponseFilterClicked = 'ChallengesResponseRejectedResponseFilterClicked',
	ChallengesResponseFinalizedFilterToggleClicked = 'ChallengesResponseFinalizedFilterToggleClicked',
	ChallengeResponseApproveClicked = 'ChallengeResponseApproveClicked',
	ChallengeResponseRejectClicked = 'ChallengeResponseRejectClicked',
	ChallengeResponseScheduleCallClicked = 'ChallengeResponseScheduleCallClicked',
	ChallengeResponseFinalizeClicked = 'ChallengeResponseFinalizeClicked',
	ChallengeResponseResetSelectionClicked = 'ChallengeResponseResetSelectionClicked',
	ChallengeResponseFinalizeMultipleClicked = 'ChallengeResponseFinalizeMultipleClicked',
	ChallengeResponseResetMultipleClicked = 'ChallengeResponseResetMultipleClicked',
	ChallengeResponseListNextPageClicked = 'ChallengeResponseListNextPageClicked',
	ChallengeResponseListPreviousPageClicked = 'ChallengeResponseListPreviousPageClicked',
	ChallengeResponseListPageClicked = 'ChallengeResponseListPageClicked',
	ChallengesListAddChallengeClicked = 'ChallengesListAddChallengeClicked',
	ChallengesListViewChallengeClicked = 'ChallengesListViewChallengeClicked',
	ChallengesListViewChallengeResponsesClicked = 'ChallengesListViewChallengeResponsesClicked',
	ChallengesListArchivedChallengesClicked = 'ChallengesListArchivedChallengesClicked',
	ChallengesListSortOrderChanged = 'ChallengesListSortOrderChanged',
	ChallengesDetailBackButtonClicked = 'ChallengesDetailBackButtonClicked',
	ChallengesDetailEditChallengeLinkClicked = 'ChallengesDetailEditChallengeLinkClicked',
	ChallengesDetailDuplicateChallengeLinkClicked = 'ChallengesDetailDuplicateChallengeLinkClicked',
	ChallengesDetailArchiveChallengeLinkClicked = 'ChallengesDetailArchiveChallengeLinkClicked',
	AuthLoginViewSignInClicked = 'AuthLoginViewSignInClicked',
	AuthLoginViewForgotPasswordClicked = 'AuthLoginViewForgotPasswordClicked',
	AuthLoginViewJoinNowClicked = 'AuthLoginViewJoinNowClicked',
	AuthViewLinkedInLinkClicked = 'AuthViewLinkedInLinkClicked',
	AuthViewInstagramLinkClicked = 'AuthViewInstagramLinkClicked',
	AuthViewTwitterLinkClicked = 'AuthViewTwitterLinkClicked',
	AuthViewYoutubeLinkClicked = 'AuthViewYoutubeLinkClicked',
	AuthSignUpViewPrivacyPolicyLinkClicked = 'AuthSignUpViewPrivacyPolicyLinkClicked',
	AuthSignUpViewTermsOfServiceLinkClicked = 'AuthSignUpViewTermsOfServiceLinkClicked',
	AuthSignUpViewLoginLinkClicked = 'AuthSignUpViewLoginLinkClicked',
	AuthOnboardingSupplierClicked = 'AuthOnboardingSupplierClicked',
	AuthOnboardingBuyerClicked = 'AuthOnboardingBuyerClicked',
	AuthOnboardingSignupJourneyBackButtonClicked = 'AuthOnboardingSignupJourneyBackButtonClicked',
	AuthOnboardingBusinessLookupBackButtonClicked = 'AuthOnboardingBusinessLookupBackButtonClicked',
	AuthOnboardingBusinessLookupResultSkipClicked = 'AuthOnboardingBusinessLookupResultSkipClicked',
	AuthOnboardingBusinessLookupResultSearchAgainClicked = 'AuthOnboardingBusinessLookupResultSearchAgainClicked',
	AuthOnboardingBusinessLookupResultSelectClicked = 'AuthOnboardingBusinessLookupResultSelectClicked',
	AuthOnboardingVerifyEmailClicked = 'AuthOnboardingVerifyEmailClicked',
	AuthOnboardingVerifyPrincipalClicked = 'AuthOnboardingVerifyPrincipalClicked',
	AuthOnboardingVerifyManualClicked = 'AuthOnboardingVerifyManualClicked',
	AuthOnboardingVerifyBackButtonClicked = 'AuthOnboardingVerifyBackButtonClicked',
	AuthOnboardingSignupForDUNSNumberClicked = 'AuthOnboardingSignupForDUNSNumberClicked',
	AuthOnboardingSkipVerificationClicked = 'AuthOnboardingSkipVerificationClicked',
	AuthOnboardingSignupForDUNSBackButtonClicked = 'AuthOnboardingSignupForDUNSBackButtonClicked',
	AuthOnboardingStartIdentityVerificationPrincipalClicked = 'AuthOnboardingStartIdentityVerificationPrincipalClicked',
	AuthOnboardingStartIdentityVerificationManualClicked = 'AuthOnboardingStartIdentityVerificationManualClicked',
	CompanySessionSelected = 'CompanySessionSelected',
	CompanySessionPageNewCompanyClick = 'CompanySessionPageNewCompanyClick',
	CompanySessionPageSignOutClick = 'CompanySessionPageSignOutClick',
	ArchivedRequestListViewRequestsLinkClicked = 'ArchivedRequestListViewRequestsLinkClicked',
	ArchivedChallengesListChallengesClicked = 'ArchivedChallengesListChallengesClicked',
	AccountAccountTabClicked = 'AccountAccountTabClicked',
	AccountCommunicationPreferenceTabClicked = 'AccountCommunicationPreferenceTabClicked',
	EmailLinkClicked = 'EmailLinkClicked',
}

export enum IntersectionEvent {
	FeedChallengesCardShown = 'FeedChallengesCardShown',
	FeedRequestCardShown = 'FeedRequestCardShown',
	VerifyBannerShown = 'VerifyBannerShown',
	AppointmentViewed = 'AppointmentViewed',
}

export interface DefaultTrackingMetadata {
	data: {
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Request Checkout Click events
 */
export interface RequestCheckoutTrackingMetadata {
	data: {
		opportunity;
		product;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Schedule Appointment Click events
 */
export interface SchedulingTrackingMetadata {
	data: {
		task;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Request Feed events
 */
export interface RequestFeedTrackingMetadata {
	data: {
		request;
		position: number;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Challenge Feed events
 */
export interface ChallengeFeedTrackingMetadata {
	data: {
		challenge;
		position: number;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Verify Banner events
 */
export interface VerifyBannerTrackingMetadata {
	data: {
		myCompany: {
			id: string;
			dunsVerified: boolean;
			owner: {
				id: string;
				__typename: 'User';
			};
			__typename: 'Company';
		};
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Landing Page events
 */
export interface LandingPageTrackingMetadata {
	data: {
		opportunityId: string;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Opportunity Detail events
 */
export interface OpportunityDetailTrackingMetadata {
	data: {
		opportunity;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Profile events
 */
export interface ProfileTrackingMetadata {
	data: {
		company;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Saved Card events
 */
export interface SavedCardTrackingMetadata {
	data: {
		opportunity;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Request List and Request Detail events
 */
export interface RequestListAndDetailTrackingMetadata {
	data: {
		request;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Post Call Engagement Modal events
 */
export interface PostCallEngagementTrackingMetadata {
	data: {
		opportunity;
		appointmentId: string;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Challenge Submissions List events
 */
export interface ChallengeSubmissionsListTrackingMetadata {
	data: {
		submission;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Add Challenge Response events
 */
export interface AddChallengeResponseTrackingMetadata {
	data: {
		opportunity;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Menu Click events
 */
export interface MenuTrackingMetadata {
	data: {
		location: 'sidemenu' | 'mobileMenu';
		previousURL: string;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Appointment Intersection events
 */
export interface AppointmentIntersectionMetadata {
	task?;
	meeting?;
	appointmentStatus: 'SCHEDULING' | 'CONFIRM' | 'UPCOMING' | 'CANCELLED';
	site: string;
	locale: string;
}

/*
 * Metadata object for Appointment Meeting Click events
 */
export interface AppointmentMeetingMetadata {
	data: {
		meeting?;
		meetingId?: string;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Challenge Response List events
 */
export interface ChallengeResponseListMetadata {
	data: {
		submission?;
		pendingSubmissionsToBeFinalized?;
		challengeId?: string;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Challenge List and Challenge Detail events
 */
export interface ChallengeListAndDetailTrackingMetadata {
	data: {
		challenge;
		site: string;
		locale: string;
	};
}

/*
 * Metadata object for Email link Tracking
 */
export interface EmailLinkClickMetadata {
	data: {
		templateName: string;
		userId: string;
		teamMemberId: string;
	};
}
