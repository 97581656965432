import cc from 'classcat';
import { BackgroundColor, TextColor } from '../global/enum';

import Icon, { Props as IconProps } from '../icon';

export enum ButtonType {
	primary = 'primary',
	secondary = 'secondary',
	custom = 'custom',
}

export enum ButtonSize {
	lg = 'lg',
	sm = 'sm',
	xs = 'xs',
	full = 'full',
}

export enum ButtonTheme {
	light = 'light',
	dark = 'dark',
}

interface Props {
	children: React.ReactNode;
	icon?: IconProps;
	type?: ButtonType;
	size?: ButtonSize;
	theme?: ButtonTheme;
	backgroundColor?: BackgroundColor;
	textColor?: TextColor;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	buttonProps?: React.ComponentProps<'button'>;
	truncate?: boolean;
}

const Button = (props: Props) => {
	const {
		children,
		icon,
		type = ButtonType.primary,
		size = ButtonSize.lg,
		theme = ButtonTheme.light,
		backgroundColor,
		textColor,
		buttonProps = {},
		truncate,
		onClick,
	} = props;

	const { className, ...restButtonProps } = buttonProps;

	return (
		<button
			onClick={onClick}
			data-button-type={type}
			className={cc([
				'group items-center whitespace-nowrap rounded-skin-button font-body',
				truncate ? 'inline-block truncate' : 'flex',
				{
					'bg-skin-button-base text-white': type === ButtonType.primary,
					'text-white hover:bg-opacity-80 disabled:cursor-not-allowed disabled:bg-neutral-500 disabled:text-neutral-300':
						type === ButtonType.primary,
					'border border-black bg-white hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-10 disabled:cursor-not-allowed disabled:border-neutral-500 disabled:text-neutral-500':
						type === ButtonType.secondary,
					'border border-white bg-black text-white hover:bg-black hover:bg-opacity-80 active:bg-black active:bg-opacity-80':
						theme === ButtonTheme.dark,
					'text-black':
						theme === ButtonTheme.light && type === ButtonType.secondary,
					'h-10 px-4 md:text-sm lg:px-6 lg:text-base': size === 'xs',
					'h-10 px-6': size === 'sm',
					'h-14 px-6': size === 'lg',
					'h-14 w-full items-center justify-center px-6': size === 'full',
				},
				backgroundColor,
				textColor,
				className,
			])}
			type="button"
			{...restButtonProps}
		>
			{icon && (
				<Icon
					className="mr-2 group-disabled:stroke-current group-disabled:text-neutral-300"
					{...icon}
				/>
			)}
			{children}
		</button>
	);
};

export default Button;
