import { PropsWithChildren } from 'react';
import * as React from 'react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/link';

interface BannerProps extends PropsWithChildren {
	isDismissible?: boolean;
}

export const Banner: React.FC<BannerProps> = (props) => {
	const { children, isDismissible = true } = props;

	const [isShown, setIsShown] = React.useState(true);

	return (
		<>
			{isShown && (
				<div className="bg-skin-banner-info">
					<div className="mx-auto py-3 px-3 sm:px-6 lg:px-8">
						<div className="flex flex-wrap items-center justify-between">
							{children}
							{isDismissible && (
								<div className="order-2 shrink-0 sm:order-3 sm:ml-3">
									<button
										type="button"
										className="-mr-1 flex rounded-md p-2 hover:bg-info-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
										onClick={() => {
											setIsShown(!isShown);
										}}
									>
										<span className="sr-only">Dismiss</span>

										<XIcon className={'h-6 w-6 text-skin-alert-icon-info'} />
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

interface BannerMessageProps {
	children: React.ReactNode;
}

export const BannerMessage: React.FC<BannerMessageProps> = (props) => {
	const { children } = props;

	return (
		<div className="flex w-0 flex-1 items-center">
			<p className="ml-3 truncate font-medium text-skin-banner-message">
				<span>{children}</span>
			</p>
		</div>
	);
};

interface BannerActionProps extends PropsWithChildren {
	linkUrl: string;
	onClick?: () => void;
}

export const BannerAction: React.FC<BannerActionProps> = (props) => {
	const { children, linkUrl, onClick } = props;

	return (
		<div className="order-3 mt-2 shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
			<Link
				href={linkUrl}
				className="shadow-sm flex items-center justify-center rounded-md border border-transparent bg-skin-button-text px-4 py-2 text-sm font-medium text-skin-banner-button-text hover:bg-opacity-90"
				onClick={onClick}
			>
				{children}
			</Link>
		</div>
	);
};
