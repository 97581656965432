import {
	defaultTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import * as React from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/client';
import { NavbarNotifications } from '@vintro/components/vintro/navigation/navbar/navbar';
import { useAnalytics } from 'use-analytics';
import { useMyNotifications } from '@theme/hooks/useMyNotifications';

export enum NOTIFICATION_TYPE {
	/* Initial Order was created, but dates were never sent */
	SCHEDULING_PENDING_INITIAL_DATES = 'SCHEDULING_PENDING_INITIAL_DATES',
	/* First set of dates sent */
	SCHEDULING_INITIAL_DATES = 'SCHEDULING_INITIAL_DATES',
	/* Alternative dates were scheduled */
	SCHEDULING_COUNTER_PARTY_SUGGESTED_NEW_DATES = 'SCHEDULING_COUNTER_PARTY_SUGGESTED_NEW_DATES',
}

export const NOTIFICATION_MAPPER = {
	[NOTIFICATION_TYPE.SCHEDULING_PENDING_INITIAL_DATES]: (notification) => ({
		id: notification.id,
		date: new Date(notification.createdAt),
		message: `Schedule your meeting with ${notification.parent.counterParty.teamMember.user.firstName} @ ${notification.parent.counterParty.teamMember.company.name}`,
		linkURL: `/dashboard/appointments/schedule/${notification.parent.id}`,
		linkLabel: 'Schedule meeting',
		icon: 'CalendarIcon',
		isSolidIcon: true,
	}),
	[NOTIFICATION_TYPE.SCHEDULING_INITIAL_DATES]: (notification) => ({
		id: notification.id,
		date: new Date(notification.createdAt),
		message: `${notification.parent.counterParty.teamMember.user.firstName} @ ${notification.parent.counterParty.teamMember.company.name} sent a meeting request.`,
		linkURL: `/dashboard/appointments/schedule/${notification.parent.id}`,
		linkLabel: 'Confirm meeting times',
		icon: 'CalendarIcon',
		isSolidIcon: true,
	}),
	[NOTIFICATION_TYPE.SCHEDULING_COUNTER_PARTY_SUGGESTED_NEW_DATES]: (
		notification,
	) => ({
		id: notification.id,
		date: new Date(notification.createdAt),
		message: `${notification.parent.counterParty.teamMember.user.firstName} @ ${notification.parent.counterParty.teamMember.company.name} suggested new times for your meeting.`,
		linkURL: `/dashboard/appointments/schedule/${notification.parent.id}`,
		linkLabel: 'Confirm meeting times',
		icon: 'CalendarIcon',
		isSolidIcon: true,
	}),
};

export const READ_NOTIFICATION = gql`
	mutation($id: ID!) {
		readNotification(id: $id) {
			id
		}
	}
`;

export const mapNotification = (notification) => {
	return NOTIFICATION_MAPPER[notification.type]
		? NOTIFICATION_MAPPER[notification.type](notification)
		: null;
};

export const NavigationPrimaryNotifications = () => {
	const { track } = useAnalytics();
	const { data, loading, error } = useMyNotifications();
	const client = useApolloClient();

	if (loading || error) {
		return <NavbarNotifications messages={[]} />;
	}

	const mapOnCloseEvent = (notification) => {
		return {
			...notification,
			onCloseMessage: async () => {
				try {
					await client.mutate({
						mutation: READ_NOTIFICATION,
						variables: { id: notification.id },
					});
				} catch (e) {
					//
				}
			},
		};
	};

	const notificationMessages = data?.me?.teamMember?.myNotifications?.results
		? data?.me?.teamMember?.myNotifications?.results
				.map(mapNotification)
				.filter((n) => n !== null)
				.map(mapOnCloseEvent)
		: [];

	return (
		<NavbarNotifications
			messages={notificationMessages}
			onClick={() => {
				track(
					TrackEvent.NavigationNotificationsClicked,
					defaultTrackingMetadata,
				);
			}}
		/>
	);
};
