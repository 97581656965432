import { useME } from '@theme/hooks/useMe';
import { Vertical } from '@theme/types/company';
import { COOKIES } from '@theme/utils/cookies';
import { setCookie } from 'cookies-next';
import { useSession } from 'next-auth/react';

export const useCompanyVerticals = () => {
	const { data } = useME(true, 'cache-first');
	const { status } = useSession();

	const verticals = data?.myCompany?.verticals;

	const isRequestCreator =
		verticals && verticals.includes(Vertical.REQUEST_CREATOR);
	const isRequestResponder =
		verticals && verticals.includes(Vertical.REQUEST_RESPONDER);
	const isChallengeCreator =
		verticals && verticals.includes(Vertical.CHALLENGE_CREATOR);
	const isChallengeResponder =
		verticals && verticals.includes(Vertical.CHALLENGE_RESPONDER);

	if (verticals && status === 'authenticated') {
		setCookie(COOKIES.VERTICALS, JSON.stringify(verticals));
	}

	return {
		isRequestCreator,
		isRequestResponder,
		isChallengeCreator,
		isChallengeResponder,
	};
};
