import * as React from 'react';
import cc from 'classcat';
import Icon, { Props as IconProps } from '../icon';

// @todo navbar is temporary enum that will be used in theming buildout
export enum TextButtonType {
	grey = 'grey',
	primary = 'primary',
	white = 'white',
	navbar = 'navbar',
	custom = 'custom',
}

export enum TextButtonSize {
	md = 'md',
	sm = 'sm',
	full = 'full',
}

export enum TagType {
	button = 'button',
	a = 'a',
}

interface Props {
	children: React.ReactNode;
	type?: TextButtonType;
	color?: string;
	size?: TextButtonSize;
	textSize?: string;
	className?: string;
	as?: TagType;
	leftIcon?: IconProps;
	rightIcon?: IconProps;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	href?: string;
	anchorProps?: React.ComponentProps<'a'>;
	buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const TextButton = React.forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	Props
>((props, ref) => {
	const {
		children,
		type = TextButtonType.grey,
		color,
		size = TextButtonSize.md,
		as = TagType.button,
		textSize = 'w-max',
		className,
		leftIcon,
		rightIcon,
		onClick,
		href,
		buttonProps = {},
		anchorProps = {},
	} = props;

	const { className: buttonClassName, ...restButtonProps } = buttonProps;
	const { className: anchorClassName, ...restAnchorProps } = anchorProps;
	const customStyle = color ? { color } : null;

	const content = (
		<>
			{leftIcon && (
				<Icon
					className={cc([
						{
							'stroke-current text-skin-button-text hover:text-skin-button-text active:text-skin-button-text group-hover:text-skin-button-text':
								type === TextButtonType.primary,
							'text-neutral-500 hover:text-neutral-600 active:opacity-80':
								type === TextButtonType.grey,
						},
						'mr-2',
					])}
					{...leftIcon}
				/>
			)}
			<span
				className={cc([
					{
						'hover:opacity-80 active:opacity-60':
							type === TextButtonType.custom,
						'text-neutral-500 hover:text-neutral-600 active:opacity-80':
							type === TextButtonType.grey,
						'text-skin-navbar-base hover:opacity-80 active:opacity-60':
							type === TextButtonType.navbar,
						'group text-skin-button-text hover:text-skin-button-text active:text-skin-button-text':
							type === TextButtonType.primary,
						'text-white hover:opacity-80 active:opacity-60':
							type === TextButtonType.white,
					},
					{
						'md:text-sm lg:text-base': size === TextButtonSize.sm,
						'w-full items-center justify-center': size === TextButtonSize.full,
					},
					textSize,
					className,
				])}
				style={customStyle}
			>
				{children}
			</span>
			{rightIcon && (
				<Icon
					className={cc([
						{
							'stroke-current text-skin-button-text hover:text-skin-button-text active:text-skin-button-text group-hover:text-skin-button-text':
								type === TextButtonType.primary,
							'text-neutral-500 hover:text-neutral-600 active:opacity-80':
								type === TextButtonType.grey,
						},
						'ml-2',
					])}
					{...rightIcon}
				/>
			)}
		</>
	);

	return (
		<>
			{as === TagType.button && (
				<button
					ref={ref as React.MutableRefObject<HTMLButtonElement>}
					onClick={onClick}
					className={cc([
						'group flex items-center font-headline text-base text-neutral-500',
						{
							'hover:opacity-80 active:opacity-60':
								type === TextButtonType.custom,
							'text-neutral-500 hover:text-neutral-600 active:opacity-80':
								type === TextButtonType.grey,
							'text-skin-navbar-base hover:opacity-80 active:opacity-60':
								type === TextButtonType.navbar,
							'group text-skin-button-text hover:text-skin-button-text active:text-skin-button-text':
								type === TextButtonType.primary,
							'text-white hover:opacity-80 active:opacity-60':
								type === TextButtonType.white,
						},
						buttonClassName,
						'items-center',
					])}
					type="button"
					{...restButtonProps}
				>
					{content}
				</button>
			)}

			{as === TagType.a && (
				<a
					ref={ref as React.MutableRefObject<HTMLAnchorElement>}
					href={href}
					className={cc([
						'group',
						'flex items-center font-headline text-base text-neutral-500',
						{
							'no-underline': as === TagType.a,
						},
						anchorClassName,
					])}
					{...restAnchorProps}
				>
					{content}
				</a>
			)}
		</>
	);
});

export default TextButton;
