import { useME } from '@theme/hooks/useMe';
import { useRouter } from 'next/router';
import * as React from 'react';

declare global {
	interface Window {
		onUsersnapCXLoad: (api) => void;
		Intercom;
		intercomSettings;
		attachEvent;
	}
}

export const Usersnap: React.FC = () => {
	const router = useRouter();
	// Valid if the url comes from a team member invitation
	const isTeamMemberInvite =
		router.pathname.startsWith('/team-invite') ||
		!!router.query?.returnUrl?.includes('team_invite_');
	// Validates if the route is of type signup
	const signupRoute = router.pathname !== '/signup' && !isTeamMemberInvite;

	const hasCompanySession =
		router.pathname !== '/login/workspace' &&
		router.pathname !== '/login' &&
		signupRoute &&
		router.pathname !== '/'
			? true
			: false;

	const { data, loading, error } = useME(hasCompanySession, 'cache-first');

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			window.onUsersnapCXLoad = (api) => {
				api.init({
					custom: {
						companyId: data?.myCompany?.id,
						companyName: data?.myCompany?.name,
						companyVerified: data?.myCompany?.dunsVerified,
						companyOwnerId: data?.myCompany?.owner?.id,
					},
					user: {
						email: data?.me?.email,
					},
				});

				api.show(`${process.env.NEXT_PUBLIC_USERSNAP_PROJECT_KEY}`);
			};
		}

		if (!loading) {
			const script = document.createElement('script');

			script.src = `https://widget.usersnap.com/global/load/${process.env.NEXT_PUBLIC_USERSNAP_KEY}?onload=onUsersnapCXLoad`;
			script.async = true;

			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, [data, loading]);

	if (loading) return null;
	if (error) return null;

	return null;
};
