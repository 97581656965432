import { useState } from 'react';
import cc from 'classcat';
import Icon from '../icon/index';
interface Props {
	icon?: string;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const IconButton = (props: Props) => {
	const { icon, onClick } = props;

	const [isActive, setIsActive] = useState(false);

	const toggleActive = () => {
		setIsActive(!isActive);
	};

	const mouseUp = (e) => {
		toggleActive();
		// Custom on click that comes from props
		onClick(e);
	};

	const mouseDown = () => {
		toggleActive();
	};

	return (
		<button
			onMouseUp={mouseUp}
			onMouseDown={mouseDown}
			className={cc([
				'group flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border-0 bg-skin-button-icon text-skin-button-icon transition-none hover:cursor-pointer active:cursor-pointer',
				{
					'border-0 bg-skin-navbar-base': isActive,
				},
			])}
		>
			<Icon
				name={icon}
				className={cc([
					'h-6 w-6 stroke-current text-skin-button-icon group-active:stroke-white',
				])}
			/>
		</button>
	);
};

export default IconButton;
