import { WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const useMyNotifications = (
	fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
) => {
	const { data, loading, error } = useQuery(GET_NOTIFICATIONS, { fetchPolicy });

	return {
		data,
		loading,
		error,
	};
};

export const GET_NOTIFICATIONS = gql`
	query GET_NOTIFICATIONS {
		me {
			id
			teamMember {
				id
				myNotifications {
					cursor
					results {
						id
						type
						createdAt
						parent {
							... on Task {
								id
								counterParty {
									teamMember {
										id
										company {
											id
											name
										}
										user {
											id
											firstName
											lastName
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
