import * as React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import cc from 'classcat';
import { debounce } from 'lodash';

export enum SearchInputSize {
	sm = 'small',
	lg = 'large',
}

export interface Props {
	bordered?: boolean;
	onSearchChange?: (searchQuery: string) => void;
	size?: SearchInputSize;
	inputProps: { disabled?: boolean; id: string; placeholder: string };
}

const DEFAULT_SEARCH_QUERY = '';

export const Search: React.FC<Props> = React.forwardRef((props, ref) => {
	const {
		onSearchChange,
		bordered = true,
		size = SearchInputSize.lg,
		inputProps = { disabled: true },
	} = props;
	const [searchQuery, setSearchQuery] = React.useState(DEFAULT_SEARCH_QUERY);
	const [inputFocus, setInputFocus] = React.useState(false);
	const searchInputRef = React.useRef(null);

	const clear = () => {
		setSearchQuery(DEFAULT_SEARCH_QUERY);
		searchInputRef.current.value = DEFAULT_SEARCH_QUERY;
	};

	React.useImperativeHandle(ref, () => ({ clear }));

	React.useEffect(() => {
		onSearchChange(searchQuery);
	}, [searchQuery]);

	const toggleInputFocus = () => {
		setInputFocus(!inputFocus);
	};

	const searchInputChangeHandler = (e) => {
		setSearchQuery(e.target.value);
	};

	const debouncedChangeHandler = React.useMemo(
		() => debounce(searchInputChangeHandler, 200),
		[],
	);

	const renderLeadingIcon = () => {
		return (
			<span>
				<SearchIcon
					className={cc([
						{
							'text-skin-base-icon': inputFocus,
						},
						'h-5 w-5',
					])}
				/>
			</span>
		);
	};

	const renderSearchInput = () => {
		return (
			<input
				type="text"
				ref={searchInputRef}
				onChange={debouncedChangeHandler}
				onFocus={toggleInputFocus}
				onBlur={toggleInputFocus}
				className={cc([
					`block w-full cursor-pointer rounded-full bg-white pl-11 pr-4 font-headline text-tiny
					text-skin-base focus:bg-white disabled:cursor-not-allowed disabled:bg-gray-100`,
					{
						'border-transparent focus:border-transparent focus:ring-transparent disabled:border-transparent': !bordered,
						'border-gray-300 focus:border-skin-base focus:ring-primary disabled:border-gray-400': bordered,
						'py-1': size === SearchInputSize.sm,
						'py-3': size === SearchInputSize.lg,
					},
				])}
				{...inputProps}
			/>
		);
	};

	const renderResetSearchQueryAction = () => {
		return (
			<button className="text-gray-500" onClick={clear} aria-label="Clear">
				<XCircleIcon className={cc(['h-5 w-5'])} />
			</button>
		);
	};

	return (
		<div>
			<div className={cc(['w-full'])}>
				<div className={cc(['relative rounded-full'])}>
					<div
						className={cc([
							'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 text-gray-500',
						])}
					>
						{renderLeadingIcon()}
					</div>
					<div>{renderSearchInput()}</div>
					<div
						className={cc([
							'absolute inset-y-0 right-0 flex items-center pr-4',
						])}
					>
						{searchQuery.length > 0 && <>{renderResetSearchQueryAction()}</>}
					</div>
				</div>
			</div>
		</div>
	);
});
