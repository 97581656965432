import '../styles/globals.css';
import 'react-dates/initialize';
import { AnalyticsTrackPageload } from '@/components/analytics-track-pageload';
import { returnPageTitle } from '@theme/theme/features/page-title';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { GlobalNotifications } from '@/components/global-notifications/global-notifications';
import { ApolloProvider, client } from '@/components/services/apollo/client';
import { AnalyticsProvider } from 'use-analytics';
import analytics from 'analytics/analytics';
import { Usersnap } from '@theme/utils/usersnap/usersnap';
import Head from 'next/head';
import { FeatureToggle } from '@/components/feature-toggle/feature-toggle';
import { enabledFeatures } from '@theme/hooks/useFeatureToggle';
import * as React from 'react';
import { useRouter } from 'next/router';
import { IntercomProvider } from '@theme/utils/intercom/intercomProvider';
import { promptConfirmRouteChange } from '@theme/utils/prompt-confirm-route-change';
import { LayoutDashboard } from '@/components/layouts/dashboard/dashboard';
import { LayoutStyledAuth } from '@/components/layouts/auth/auth';
import { LayoutWorkspace } from '@/components/layouts/auth/workspace';
import { NextPage } from 'next';
import { LayoutMarketingSprinklerLanding } from '@/components/layouts/generic/marketing-sprinkler-landing';
import { LayoutContactInfo } from '@theme/components/layouts/generic/contact-info';

const SupportWrapper =
	process.env.NEXT_PUBLIC_DISPLAY_INTERCOM === 'true'
		? IntercomProvider
		: LayoutContactInfo;

export type PageComponent = NextPage & {
	layout: 'auth' | 'dashboard' | 'workspace' | 'marketing-landing';
	active?: string;
	fullScreen?: boolean;
};

const MyApp = (props) => {
	const { Component, pageProps } = props;

	const router = useRouter();

	React.useEffect(() => {
		promptConfirmRouteChange(router);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // adding router to the dependency array causes the prompt to show up twice

	// reload page on back/forward events to ensure get updated data
	React.useEffect(() => {
		router.beforePopState(() => {
			location?.reload();
			return false;
		});
	}, [router]);

	return (
		<>
			<FeatureToggle enabledFeatures={enabledFeatures}>
				<Head>
					<title>{returnPageTitle()}</title>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
					/>
				</Head>

				<AnalyticsProvider instance={analytics}>
					<AuthProvider session={pageProps?.session}>
						<ApolloProvider client={client}>
							<SupportWrapper>
								{process.env.NEXT_PUBLIC_DISPLAY_USERSNAP === 'true' && (
									<Usersnap />
								)}
								<AnalyticsTrackPageload />
								<div
									id="app-theme-container"
									className={process.env.NEXT_PUBLIC_APP_SITE}
								>
									<GlobalNotifications />

									{Component.layout === 'dashboard' && (
										<LayoutDashboard
											active={Component.active}
											fullScreen={Component.fullScreen}
										>
											<Component {...pageProps} />
										</LayoutDashboard>
									)}

									{Component.layout === 'auth' && (
										<LayoutStyledAuth>
											<Component {...pageProps} />
										</LayoutStyledAuth>
									)}

									{Component.layout === 'workspace' && (
										<LayoutWorkspace className="max-w-lg">
											<Component {...pageProps} />
										</LayoutWorkspace>
									)}

									{Component.layout === 'marketing-landing' && (
										<LayoutMarketingSprinklerLanding>
											<Component {...pageProps} />
										</LayoutMarketingSprinklerLanding>
									)}

									{Component.layout === undefined && (
										<Component {...pageProps} />
									)}
									<div id="app-modal-portal"></div>
								</div>
							</SupportWrapper>
						</ApolloProvider>
					</AuthProvider>
				</AnalyticsProvider>
			</FeatureToggle>
		</>
	);
};

export default MyApp;
