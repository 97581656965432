import { useME } from '@theme/hooks/useMe';
import { COOKIES } from '@theme/utils/cookies';
import { setCookie } from 'cookies-next';
import { useSession } from 'next-auth/react';

enum UserRole {
	ADMIN = 'ADMIN',
	MEMBER = 'MEMBER',
}

export const useUserRoles = () => {
	const { data, loading } = useME(true, 'cache-first');
	const { status } = useSession();

	const roles = data?.me?.teamMember?.roles;

	const isAdmin = roles && roles.includes(UserRole.ADMIN);
	const isMember = roles && roles.includes(UserRole.MEMBER);

	if (roles && status === 'authenticated') {
		setCookie(COOKIES.USER_ROLES, JSON.stringify(roles));
	}

	return {
		isAdmin,
		isMember,
		loading,
	};
};
