import * as React from 'react';
import cc from 'classcat';

interface LoadingSpinnerProps {
	children?;
	className?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
	const { className = 'bg-transparent' } = props;

	return (
		<div
			className={cc([
				'flex h-full w-full items-center justify-center py-4 px-6',
				className,
			])}
		>
			<div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-skin-base"></div>
		</div>
	);
};
