import vintroSocialLinksJSON from '../social-links/vintro.json';
import dnbSocialLinksJSON from '../social-links/dnb.json';
import opennationSocialLinksJSON from '../social-links/opennation.json';

interface SocialLinks {
	linkedin: string;
	twitter: string;
	youtube: string;
	instagram: string;
}

export const getSocialLinks = (): SocialLinks => {
	// The production build needs the env var to be cast to string explicitly
	const appSite: string = (process.env.NEXT_PUBLIC_APP_SITE || '').toString();

	if (appSite === 'dnb') {
		return dnbSocialLinksJSON;
	} else if (appSite === 'opennation') {
		return opennationSocialLinksJSON;
	} else {
		return vintroSocialLinksJSON;
	}
};
