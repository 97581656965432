import * as React from 'react';
import {
	QuestionMarkCircleIcon,
	ChevronDownIcon,
} from '@heroicons/react/solid';
import {
	GlobeIcon,
	MailIcon,
	PhoneOutgoingIcon,
} from '@heroicons/react/outline';
import {
	HeadingSize,
	TextHeading,
	TextParagraph,
	TextParagraphSize,
} from '@vintro/components/vintro/typography/typography';
import { useClickOutside } from '@theme/../components/utils/hooks/useClickOutside';
import { getContactInfo } from '@theme/theme/features/contact-info';

const contactInfo = getContactInfo();
interface LayoutContactInfoProps {
	children: React.ReactNode;
}

export const LayoutContactInfo: React.FC<LayoutContactInfoProps> = (props) => {
	const { children } = props;
	const containerRef = React.useRef(null);
	const [contactInfoIsOpen, setContactInfoIsOpen] = React.useState(false);

	useClickOutside(containerRef, contactInfoIsOpen, () =>
		setContactInfoIsOpen(false),
	);

	return (
		<>
			{children}

			<div className="fixed left-6 bottom-6 right-6 sm:left-auto">
				{contactInfoIsOpen && (
					<div
						className="mb-2 flex flex-col gap-4 bg-skin-contact-us-base p-8 align-bottom text-skin-contact-us-base shadow-lg"
						ref={containerRef}
					>
						<TextHeading headingSize={HeadingSize.h4}>Need help?</TextHeading>

						<TextParagraph textParagraphSize={TextParagraphSize.p5}>
							If you have any questions, you can contact us by:
						</TextParagraph>

						{contactInfo.phone && (
							<div className="flex">
								<PhoneOutgoingIcon className="mr-2 h-6 w-6 shrink-0 text-skin-contact-us-base" />

								<TextParagraph textParagraphSize={TextParagraphSize.p5}>
									<label className="mr-1 font-headline">Phone:</label>
									<a className="underline" href={`tel:${contactInfo.phone}`}>
										{contactInfo.phone}
									</a>
								</TextParagraph>
							</div>
						)}

						{contactInfo.email && (
							<div className="flex">
								<MailIcon className="mr-2 h-6 w-6 shrink-0 text-skin-contact-us-base" />

								<TextParagraph textParagraphSize={TextParagraphSize.p5}>
									<label className="mr-1 font-headline">Email:</label>
									<a className="underline" href={`mailto:${contactInfo.email}`}>
										{contactInfo.email}
									</a>
								</TextParagraph>
							</div>
						)}

						{contactInfo.address && (
							<div className="flex">
								<GlobeIcon className="mr-2 h-6 w-6 shrink-0 text-skin-contact-us-base" />

								<TextParagraph textParagraphSize={TextParagraphSize.p5}>
									<label className="mr-1 font-headline">Address:</label>
									<span>{contactInfo.address}</span>
								</TextParagraph>
							</div>
						)}
					</div>
				)}

				<button
					className="ml-auto flex h-14 w-14 items-center justify-center rounded-full bg-skin-contact-us-icon"
					onClick={() =>
						setContactInfoIsOpen(
							(prevContactInfoIsOpen) => !prevContactInfoIsOpen,
						)
					}
				>
					{contactInfoIsOpen ? (
						<ChevronDownIcon className="h-7 w-7 text-white" />
					) : (
						<QuestionMarkCircleIcon className="h-7 w-7 text-white" />
					)}
				</button>
			</div>
		</>
	);
};
