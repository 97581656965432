import moment from 'moment';
import { returnDefaultLocale } from '@vintro/app/utils/locale';

export const formatCurrency = (
	value: number,
	options: {
		locale: string;
		currency: string;
		style?: string;
		minimumFractionDigits?: number;
	} = {
		locale: 'en-US',
		currency: 'USD',
		style: 'currency',
		minimumFractionDigits: 2,
	},
): string => {
	const { locale, ...formatOptions } = options;

	return Intl.NumberFormat(locale, formatOptions).format(value);
};

export const formatNumber = (
	element: string,
	locale: string,
	acceptZeroDecimal = false,
): string => {
	let parsedNumber = element;
	if (/^[0-9]+(\.[0-9]{1,2})?$/.test(element)) {
		parsedNumber = parseFloat(element).toLocaleString(locale);

		/**
		 * If the decimal part of the parsed number is different
		 * from the decimal part of the original number, the decimal part of the original
		 * number is added to the parsed number to ensure
		 * that it has the same decimal value as the original number.
		 */
		if (acceptZeroDecimal) {
			const elementParams = element.split('.');
			const parsedNumberParams = parsedNumber.split('.');

			if (elementParams?.[1] !== parsedNumberParams?.[1]) {
				parsedNumber = parsedNumberParams[0] + '.' + elementParams[1];
			}
		}
	}

	return parsedNumber;
};

/**
 * Used to remove thousand separator on numbers,
 * this separator is obtained dinamically
 * @param value string
 * @returns string
 */
export const stripCharacters = (value: string): string => {
	// @todo how do we long term solve for all locale other than en-US, en-GB
	const regex = new RegExp(`\\b,\\b`, 'g');
	const formattedValue = value.replace(regex, '');

	if (value.length > 0) {
		return formattedValue;
	} else {
		return value;
	}
};

export const formatDate = (
	value: string,
	options: {
		locale: string | string[];
		styles?: Intl.DateTimeFormatOptions;
	} = {
		locale: returnDefaultLocale(),
		styles: { timeZoneName: 'short' },
	},
): string => {
	const { locale, styles } = options;

	return new Intl.DateTimeFormat(locale, styles).format(new Date(value));
};

export const formatDateTimeAsTimeAgo = (date: string): string => {
	return moment(date).fromNow();
};

export const formatDateTimeUntilTime = (
	endDate: string,
	startDate?: string,
): string => {
	const start = startDate ? moment(startDate) : moment();
	const end = moment(endDate);
	return end.from(start);
};

export const formatRange = (min: number, max: number): string => {
	return `$${min} - $${max}`;
};
