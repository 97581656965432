import * as vintroFlagsJSON from './vintro/features.json';
import * as vintroDevFlagsJSON from './vintro-dev/features.json';
import * as vintroTestFlagsJSON from './vintro-test/features.json';
import * as dnbFlagsJSON from './dnb/features.json';
import * as opennationFlagJSON from './opennation/features.json';

export interface features {
	requests: boolean;
	challenges: boolean;
	showCopyrightInline: boolean;
}

export enum FeatureFlag {
	'requests' = 'requests',
	'challenges' = 'challenges',
	'showCopyrightInline' = 'showCopyrightInline',
}

const setFeatureFlags = (): features => {
	// The production build needs the env var to be cast to string explicitly
	const appSite: string = (process.env.NEXT_PUBLIC_APP_SITE || '').toString();

	if (appSite === 'dnb') {
		return dnbFlagsJSON;
	} else if (appSite === 'opennation') {
		return opennationFlagJSON;
	} else if (appSite === 'vintro-dev') {
		return vintroDevFlagsJSON;
	} else if (appSite === 'vintro-test') {
		return vintroTestFlagsJSON;
	} else {
		return vintroFlagsJSON;
	}
};

export const featureFlags = setFeatureFlags();
