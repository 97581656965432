import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';

interface PanelProps extends PropsWithChildren {
	className?: string;
}

interface PanelBreakProps {
	className?: string;
}

export const Panel: React.FC<PanelProps> = (props) => {
	const { children, className } = props;

	const classNames = cc([
		'bg-white rounded-skin-panel shadow-skin-panel panel p-8 space-y-8',
		className,
	]);

	return <div className={classNames}>{children}</div>;
};

export const PanelBreak: React.FC<PanelBreakProps> = (props) => {
	const { className } = props;
	const classNames = cc([
		'border-gray-100 -mx-4 my-2 border panel-break',
		className,
	]);

	return <hr className={classNames} />;
};
