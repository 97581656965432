import { AppSiteAwareText } from '@/components/app-site-aware-text';
import { AppSiteAwareTextKeys } from '@/components/app-site-aware-text/definitions/definition-keys';
import { useReactiveVar } from '@apollo/client';
import { useFeatureToggle } from '@theme/hooks/useFeatureToggle';
import { formFooterHeightVar } from '@theme/state/layout';
import { FeatureFlag } from '@theme/theme/features/feature-flags';
import cc from 'classcat';
import * as React from 'react';

export const Copyright = (props) => {
	const {
		positionClasses,
		screenSize,
		containerClasses,
		inline = false,
	} = props;
	const formFooterHeight = useReactiveVar(formFooterHeightVar);
	const [isEnabled] = useFeatureToggle();
	const copyrightDate = new Date().getFullYear();

	/*
	 * Feature flag to toggle showing the copyright information in the application at the end of a pages content.
	 */
	if (inline && !isEnabled(FeatureFlag.showCopyrightInline)) {
		return null;
	}

	return (
		<div
			className={cc([
				'copyright-text',
				{
					[containerClasses]: screenSize === 'desktop' && !inline,
					[positionClasses]: screenSize === 'desktop' && !inline,
					'my-8 flex cursor-default justify-center text-sm opacity-50': inline,
					'mb-[72px]': formFooterHeight > 0,
				},
			])}
		>
			<AppSiteAwareText
				textKey={AppSiteAwareTextKeys['copyright.text']}
				dynamicValues={[{ key: '|copyrightDate|', value: copyrightDate }]}
			/>
		</div>
	);
};
