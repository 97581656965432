import cc from 'classcat';

export enum CircleButtonType {
	text = 'text',
	image = 'image',
	counter = 'counter',
	notifications = 'notifications',
	play = 'play',
}

interface Props {
	children: React.ReactNode;
	type?: CircleButtonType;
	disabled?: boolean;
	className?: string;
	selected?: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CircleButton = (props: Props) => {
	const {
		children,
		disabled = false,
		selected = false,
		type = CircleButtonType.text,
		className,
		onClick,
	} = props;

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={cc([
				'circle-button group box-border flex items-center justify-center overflow-hidden rounded-full border-0 text-center font-body leading-none active:cursor-pointer active:text-white',
				{
					'relative bg-skin-button-circle text-skin-button-circle hover:cursor-pointer hover:rounded-full hover:border-none active:border-none active:bg-skin-navbar-base':
						type === CircleButtonType.notifications ||
						type === CircleButtonType.counter,
					'border-none bg-skin-button-base text-skin-button-base': selected,
					'relative bg-skin-button-circle text-skin-button-circle hover:border-none active:border-none active:bg-skin-navbar-base':
						type === CircleButtonType.text,
					'relative hover:cursor-pointer hover:rounded-full hover:border-2 hover:border-solid hover:border-black active:rounded-full active:border-2 active:border-solid active:border-skin-base active:bg-skin-navbar-base':
						type === CircleButtonType.image,
					'h-auto w-auto rounded-none border-none bg-transparent hover:border-none active:border-none':
						type === CircleButtonType.play,
				},
				className,
			])}
		>
			{children}
		</button>
	);
};

export default CircleButton;
