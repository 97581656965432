import { PropsWithChildren, useRef } from 'react';
import cc from 'classcat';

import { useClickOutside } from '../../utils/hooks/useClickOutside';

interface UserMenuItemProps extends PropsWithChildren {
	className?: string;
	isSelected: boolean;
}

interface UserMenuItemsProps extends PropsWithChildren {
	className?: string;
}

interface UserMenuPanelProps extends PropsWithChildren {
	className?: string;
	isOpen: boolean;
	currentMenuItem: string;
	onClose: () => void;
}

interface UserMenuProps {
	children: React.ReactNode;
}

export const UserMenuItem: React.FC<UserMenuItemProps> = (props) => {
	const { className, children, isSelected } = props;

	return (
		<div className="px-6 font-headline text-base leading-6 text-skin-user-menu-base">
			<div
				className={cc([
					'my-1 flex h-8 cursor-pointer items-center truncate p-4 hover:rounded-skin-user-menu-item hover:bg-skin-user-menu-item-hover hover:text-skin-user-menu-item-hover',
					{
						'border-l-2 border-skin-base pl-4': isSelected,
					},
					className,
				])}
			>
				{children}
			</div>
		</div>
	);
};

export const UserMenuItems: React.FC<UserMenuItemsProps> = (props) => {
	const { className, children } = props;

	return (
		<div className={cc(['flex flex-col py-4', className])}>{children}</div>
	);
};

export const UserMenuPanel: React.FC<UserMenuPanelProps> = (props) => {
	const { className, children, isOpen, onClose } = props;

	const containerRef = useRef(null);

	useClickOutside(containerRef, isOpen, onClose);

	return (
		<div
			ref={containerRef}
			className={cc([
				{
					block: isOpen,
					hidden: !isOpen,
				},
				className,
			])}
		>
			<div className="absolute -bottom-6 left-[calc(50%_-_5px)] block h-[10px] w-[10px] rotate-45 rounded-sm bg-skin-user-menu-base md:-bottom-7"></div>
			<div className="fixed left-4 right-4 top-auto mt-4 flex w-[calc(100vw_-_32px)] flex-col divide-y divide-skin-user-menu-divide whitespace-nowrap rounded-b-sm bg-skin-user-menu-base pt-1 shadow-skin-user-menu sm:absolute sm:right-auto sm:-left-20 sm:w-[343px] sm:rounded-skin-user-menu md:mt-5">
				{children}
			</div>
		</div>
	);
};

const UserMenu: React.FC<UserMenuProps> = (props) => {
	const { children } = props;

	return <div className="relative z-10">{children}</div>;
};

export default UserMenu;
