import {
	defaultTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import { useUserRoles } from '@theme/hooks/useUserRoles';
import { removeCookies } from '@theme/utils/cookies';
import resetCache from '@theme/utils/resetCache';
import { LoadingSpinner } from '@vintro/components/vintro/loading-spinner/loading-spinner';
import {
	UserMenuItem,
	UserMenuItems,
} from '@vintro/components/vintro/user-menu';
import cc from 'classcat';
import { signIn, signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useAnalytics } from 'use-analytics';

export const UserMenuOptions = (props) => {
	const { reset, track } = useAnalytics();
	const router = useRouter();
	const { isAdmin, loading: userRolesLoading } = useUserRoles();
	const { closeUserMenu, currentPath } = props;
	const [filteredMenuOptions, setFilteredMenuOptions] = React.useState([]);

	React.useEffect(() => {
		if (!userRolesLoading) {
			const test = menuItems.map((menu) =>
				menu.filter((item) => isAdmin || !['company'].includes(item.id)),
			);
			setFilteredMenuOptions(test);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRolesLoading]);

	interface Menu {
		text: string;
		id: string;
		path?: string;
		isButton?: boolean;
		onClick?: () => Promise<void>;
	}

	const menuItems: Menu[][] = [
		[
			{
				text: 'Account',
				id: 'account',
				path: '/dashboard/account',
				onClick: async () => {
					track(
						TrackEvent.NavigationUserMenuAccountLinkClicked,
						defaultTrackingMetadata,
					);
					router.push('/dashboard/account');
					closeUserMenu();
				},
			},
			{
				text: 'Update Company Profile',
				id: 'company',
				path: '/dashboard/account/organization',
				onClick: async () => {
					track(
						TrackEvent.NavigationUserMenuUpdateCompanyProfileLinkClicked,
						defaultTrackingMetadata,
					);
					router.push('/dashboard/account/organization');
					closeUserMenu();
				},
			},
			{
				text: 'Orders',
				id: 'orders',
				path: '/dashboard/orders',
				onClick: async () => {
					track(
						TrackEvent.NavigationUserMenuOrdersLinkClicked,
						defaultTrackingMetadata,
					);
					router.push('/dashboard/orders');
					closeUserMenu();
				},
			},
			{
				text: 'Switch Organization',
				id: 'organization',
				path: '/login/workspace',
				onClick: async () => {
					track(
						TrackEvent.NavigationUserMenuSwitchOrganizationLinkClicked,
						defaultTrackingMetadata,
					);

					await signIn('companyRevoke', { redirect: false });
					await router.push('/login/workspace');

					removeCookies();
					closeUserMenu();
				},
			},
		],
		[
			{
				text: 'Logout',
				id: 'logout',
				isButton: true,
				onClick: async () => {
					track(
						TrackEvent.NavigationUserMenuLogoutClicked,
						defaultTrackingMetadata,
					);
					await signOut({ redirect: false });
					await resetCache();
					reset();

					await router.push('/login');
					removeCookies();
				},
			},
		],
	];

	if (userRolesLoading) return <LoadingSpinner />;

	return (
		<>
			{filteredMenuOptions.map((menuItems, index) => {
				return (
					<UserMenuItems key={index}>
						{menuItems.map((item) => {
							return (
								<UserMenuItem
									key={item.id}
									isSelected={currentPath === item.path}
								>
									{item.isButton ? (
										<button
											className={cc(['w-full text-left'])}
											onClick={item.onClick}
										>
											{item.text}
										</button>
									) : (
										<div
											onClick={item.onClick}
											className={cc(['navbar-item w-full'])}
										>
											{item.text}
										</div>
									)}
								</UserMenuItem>
							);
						})}
					</UserMenuItems>
				);
			})}
		</>
	);
};
