import React, { PropsWithChildren } from 'react';
import { FeatureToggleContext } from '../../context/feature-toggle-context';

interface FeatureToggleProps extends PropsWithChildren {
	enabledFeatures: string[];
}

export const FeatureToggle: React.FC<FeatureToggleProps> = (props) => {
	const { children, enabledFeatures } = props;
	return (
		<FeatureToggleContext.Provider value={{ enabledFeatures }}>
			{children}
		</FeatureToggleContext.Provider>
	);
};
